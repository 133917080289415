import { useQuery } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import { communityKeys } from '../communities.keys';
import { getCommunityOnboardingSteps } from '../communities.service';

export const useOnboardingSteps = () => {
  const subdomain = getSubdomain();

  return useQuery({
    queryKey: communityKeys.onboarding(subdomain),
    queryFn: () => getCommunityOnboardingSteps(subdomain),
    gcTime: 0,
    staleTime: 0,
  });
};
