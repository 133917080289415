import type { FetchOptions } from 'ofetch';

import type {
  GetCRMInput,
  GetCRMOutput,
  GetCRMViewInput,
  GetCRMViewOutput,
  PatchCRMViewInput,
  PatchCRMViewOutput,
  PostCRMActionInput,
  PostCRMActionOutput,
} from '@zealy/utils';

import { api } from '../services/api';

export const getCRM = ({
  path: { subdomain },
  ...options
}: Omit<FetchOptions<'json'>, 'body'> & GetCRMInput) =>
  api.get<GetCRMOutput>(`/communities/${subdomain}/crm`, options);

export const getView = ({
  path: { subdomain },
  ...options
}: Omit<FetchOptions<'json'>, 'body'> & GetCRMViewInput) =>
  api.get<GetCRMViewOutput>(`/communities/${subdomain}/crm/view`, options);

export const updateView = ({
  path: { subdomain },
  ...options
}: Omit<FetchOptions<'json'>, 'body'> & PatchCRMViewInput) =>
  api.patch<PatchCRMViewOutput>(`/communities/${subdomain}/crm/view`, options);

export const crmAction = ({
  path: { subdomain },
  ...options
}: Omit<FetchOptions<'json'>, 'body'> & PostCRMActionInput) =>
  api.post<PostCRMActionOutput>(`/communities/${subdomain}/crm/action`, options);
