import { useMutation } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import type { EmailInvite } from '../communities.service';
import { sendCommunityInvite } from '../communities.service';

/**
 * Hook for sending invites by email a community
 * @example ```
 * const { mutate, isLoading } = useSendCommunityInvites('subdomain');
 * mutate([{ email: "contact@zealy.io", role: "editor" }]);
 * ```
 */
export const useSendCommunityInvites = (subdomain?: string) => {
  const domain = subdomain || getSubdomain();

  return useMutation({
    mutationFn: (data: EmailInvite[]) => sendCommunityInvite(domain, data),
  });
};
