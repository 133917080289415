import type { ApiFetcherArgs } from '@ts-rest/core';

import type { ClientInferRequest, contract } from '@zealy/contracts';
import type { BlockchainNetwork, GetNftCollectionMetadataBatchOutput } from '@zealy/utils';
import { extractErrorMessage } from '@zealy/utils';

import { apiV2 } from '../services';
import { api } from '../services/api';

export interface ContractMetadata {
  chain: string;
  contractType: 'ERC721' | 'ERC1155';
  name: string;
  possibleSpam: boolean;
  symbol: string;
  syncedAt: string;
  tokenAddress: string;
  verifiedCollection: boolean;
}

export const getNFTCollectionMetadataBatch = async (contractAddresses: string[]) =>
  api.get<GetNftCollectionMetadataBatchOutput>(`/external/blockchain/polygon/contract/nft`, {
    query: {
      addresses: contractAddresses.join(','),
    },
  });

export const getNFTCollectionMetadata = async (
  params: {
    network: BlockchainNetwork;
    contract: string;
  },
  _next?: ApiFetcherArgs['next'],
) => {
  const res = await apiV2.blockchain.getNFTCollectionMetadata({
    params: params as ClientInferRequest<
      typeof contract.blockchain.getNFTCollectionMetadata
    >['params'],
    _next,
  });

  if (res.status === 200) {
    return res.body;
  } else {
    throw new Error(extractErrorMessage(res.body));
  }
};

export const getTokenContractMetadata = async (
  params: {
    network: BlockchainNetwork;
    contract: string;
  },
  _next?: ApiFetcherArgs['next'],
) => {
  const res = await apiV2.blockchain.getTokenContractMetadata({
    params: params as ClientInferRequest<
      typeof contract.blockchain.getTokenContractMetadata
    >['params'],
    _next,
  });

  if (res.status === 200) {
    return res.body;
  } else {
    throw new Error(extractErrorMessage(res.body));
  }
};
