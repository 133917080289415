import type { FetchOptions } from 'ofetch';

import type { ClientInferResponseBody, contract } from '@zealy/contracts';
import type {
  GetTemplateQuestInput,
  ListTaskValidationsSort,
  UpdateTokenRewardTransactionBodyInput,
} from '@zealy/schemas';
import type {
  ClaimQuestInput,
  ClaimQuestOutput,
  GetAdminQuestboardInput,
  GetAdminQuestboardOutput,
  GetAdminQuestOutput,
  GetCategoryOutput,
  GetQuestboardOutput,
  GetQuestOutput,
  GetQuestResultOutput,
  GetTaskResultOutput,
  PatchQuestInput,
  PatchQuestOutput,
  PatchReorderQuestsInput,
  PatchReorderQuestsOutput,
  PostQuestInput,
  QuestContributorFilters,
} from '@zealy/utils';
import { query } from '@zealy/contracts/src/utils';
import { extractErrorMessage } from '@zealy/utils';

import { apiV2 } from '../services';
import { api } from '../services/api';
import { AppError } from '../types';

export type GetAdminQuestboardWithClaimOutput = ClientInferResponseBody<
  typeof contract.leaderboardSprint.listQuests,
  200
>['data'];

export interface GenerateMagicQuestParams {
  prompt: string;
}

export interface GenerateMagicQuestSuggestionsParams {
  useCase: string;
  description: string;
}

export const generateMagicQuest = async (subdomain: string, body: GenerateMagicQuestParams) => {
  const res = await apiV2.magicQuest.generate({
    params: {
      subdomain,
    },
    body,
  });

  if (res.status === 200) {
    return res.body;
  } else {
    // handle error here
    throw new Error('getAdminQuestboardWithClaim failed');
  }
};

export const generateMagicQuestSuggestions = async (
  subdomain: string,
  body: GenerateMagicQuestSuggestionsParams,
) => {
  const res = await apiV2.magicQuest.generateSuggestions({
    params: {
      subdomain,
    },
    body,
  });

  if (res.status === 200) {
    return res.body;
  } else {
    // handle error here
    throw new Error('generateMagicQuestSuggestions failed');
  }
};

export const getQuestboardModule = async (
  subdomain: string,
  moduleId: string,
  filters?: QuestContributorFilters[],
) =>
  api.get<GetCategoryOutput>(`/communities/${subdomain}/questboard/v2/${moduleId}`, {
    query: {
      filters,
    },
  });

export const getQuestBoard = async (subdomain: string, filters?: QuestContributorFilters[]) =>
  api.get<GetQuestboardOutput>(`/communities/${subdomain}/questboard/v2`, {
    query: {
      filters,
    },
  });

export const testClaimQuest = (
  subdomain: string,
  questId: string,
  tasks: GetQuestOutput['tasks'],
) => {
  return (taskValues: ClaimQuestInput['taskValues']) =>
    api.post<ClaimQuestOutput>(`/communities/${subdomain}/quests/v2/${questId}/test-claim`, {
      body: {
        tasks,
        taskValues,
      },
    });
};

export const claimQuest = (subdomain: string, questId: string) => {
  return (params: Omit<ClaimQuestInput, 'id'>) =>
    api.post<ClaimQuestOutput>(`/communities/${subdomain}/quests/v2/${questId}/claim`, {
      body: JSON.stringify(params),
    });
};

export const getQuest = (subdomain: string, questId: string, options?: FetchOptions) =>
  api.get<GetQuestOutput>(`/communities/${subdomain}/quests/v2/${questId}`, {
    ...options,
  });

export const getQuests = (subdomain: string) =>
  api.get<GetQuestOutput[]>(`/communities/${subdomain}/quests`, {
    query: {
      version: 'v2',
    },
  });

export interface CreateQuestInput {
  subdomain: string;
  data: PostQuestInput;
}

export const createQuest = async ({ subdomain, data }: CreateQuestInput, generated?: boolean) => {
  const res = await apiV2.quest.createQuest({
    params: { subdomain },
    body: data,
    query: generated ? { generated: 'true' } : undefined,
  });

  if (res.status === 200) {
    return res.body;
  } else {
    // handle error here
    throw new Error(
      `createQuest failed status=${res.status}, message=${extractErrorMessage(res.body)}`,
    );
  }
};

export interface UpdateQuestInput {
  questId: string;
  subdomain: string;
  data: PatchQuestInput;
}

export const updateQuest = ({ subdomain, questId, data }: UpdateQuestInput) =>
  api.patch<PatchQuestOutput>(`/communities/${subdomain}/quests/v2/${questId}`, {
    body: JSON.stringify(data),
  });

export interface UpdateQuestsInput {
  questIds: string[];
  subdomain: string;
  data: Partial<PatchQuestInput>;
}

export const updateQuests = ({ subdomain, questIds, data }: UpdateQuestsInput) =>
  api.patch(`/communities/${subdomain}/quests/`, {
    body: JSON.stringify({ questsIds: questIds, ...data }),
  });

export const deleteQuest = ({ subdomain, questId }: { subdomain: string; questId: string }) =>
  api.delete(`/communities/${subdomain}/quests/${questId}`);

export const getAdminQuest = (subdomain: string, questId: string, options?: FetchOptions) =>
  api.get<GetAdminQuestOutput>(`/communities/${subdomain}/quests/v2/${questId}/admin`, options);

export const getAdminQuestboard = (
  subdomain: string,
  options?: Omit<FetchOptions, 'query'> & {
    query?: GetAdminQuestboardInput;
  },
) => {
  return api.get<GetAdminQuestboardOutput>(`/communities/${subdomain}/questboard/v2/admin`, {
    ...options,
    query: {
      ...query,
      filters: options?.query?.filters ? JSON.stringify(options?.query?.filters) : undefined,
    },
  });
};

export const getAdminQuestboardWithClaim = async (subdomain: string, startDate?: string) => {
  const res = await apiV2.leaderboardSprint.listQuests({
    params: {
      subdomain,
    },
    query: {
      startDate,
    },
  });

  if (res.status === 200) {
    return res.body.data;
  } else {
    // handle error here
    throw new Error('getAdminQuestboardWithClaim failed');
  }
};

export const getTemplates = (subdomain: string, options?: FetchOptions) =>
  api.get<GetAdminQuestboardOutput>(`/communities/${subdomain}/templates/v2`, options);

export const listTemplateQuests = async (subdomain: string, options?: FetchOptions) => {
  const res = await apiV2.template.listTemplateQuests({
    ...options,
    params: {
      subdomain,
    },
  });

  if (res.status === 200) {
    return res.body.data;
  } else {
    // handle error here
    throw new Error('listTemplateQuests failed');
  }
};

export const listTemplateModules = async (subdomain: string, options?: FetchOptions) => {
  const res = await apiV2.template.listTemplateModules({
    ...options,
    params: {
      subdomain,
    },
  });

  if (res.status === 200) {
    return res.body.data;
  } else {
    // handle error here
    throw new Error('listTemplateModules failed');
  }
};

export const getTemplateQuest = async (params: GetTemplateQuestInput, options?: FetchOptions) => {
  const res = await apiV2.template.getTemplateQuest({
    ...options,
    params,
  });

  if (res.status === 200) {
    return res.body;
  } else {
    // handle error here
    throw new Error('getTemplateQuest failed');
  }
};

export const getTemplate = (subdomain: string, templateId: string, options?: FetchOptions) =>
  api.get<GetAdminQuestOutput>(`/communities/${subdomain}/templates/v2/${templateId}`, options);

export const reorderQuests = ({
  subdomain,
  payload,
}: {
  subdomain: string;
  payload: PatchReorderQuestsInput;
}) =>
  api.patch<PatchReorderQuestsOutput>(`/communities/${subdomain}/quests/v2/reorder`, {
    body: payload,
  });

export const getQuestResult = (subdomain: string, questId: string, options?: FetchOptions) =>
  api.get<GetQuestResultOutput>(`/communities/${subdomain}/quests/v2/${questId}/result`, options);

export const getTaskResult = async <T extends 'text' | 'quiz' | 'file' | 'poll' | 'opinion'>(
  subdomain: string,
  questId: string,
  taskId: string,
  options?: FetchOptions,
) => {
  const data = await api.get<GetTaskResultOutput<T>>(
    `/communities/${subdomain}/quests/v2/${questId}/tasks/${taskId}/result`,
    options,
  );

  const receivedFullResult = data?.length >= Number(options?.query?.limit || 10);

  return {
    results: data,
    hasNextPage: receivedFullResult,
    nextPage: receivedFullResult ? Number(options?.query?.page || 1) + 1 : undefined,
  };
};

export type TelegramGroupInfo = {
  name?: string;
  description?: string;
  url?: string;
  imageUrl?: string;
};

export const getTelegramMetadata = (inviteLink: string) =>
  api.get<TelegramGroupInfo>(`/external/telegram/group/${encodeURIComponent(inviteLink)}`);

export type DiscordGuildInfo = {
  name?: string;
  description?: string;
  url?: string;
  imageUri?: string;
  isDiscordBotInGuild: boolean;
  id: string;
  icon?: string;
  banner?: string;
  vanity_url_code?: string;
};

export const getDiscordMetadata = (inviteLink: string) =>
  api.get<DiscordGuildInfo>(`/external/discord/${encodeURIComponent(inviteLink)}`);

export const getQuestRewards = async (subdomain: string, questId: string) => {
  const res = await apiV2.reward.get({
    params: { subdomain, questId },
  });

  if (res.status === 200) {
    return res.body.data;
  } else {
    // handle error here
    throw new Error('getQuestRewards failed');
  }
};

export const listTaskValidations = async (
  subdomain: string,
  questId: string,
  taskId: string,
  sortedBy: ListTaskValidationsSort,
) => {
  const res = await apiV2.quest.listTaskValidations({
    params: { subdomain, questId, taskId },
    query: {
      sortedBy,
    },
  });

  if (res.status === 200) {
    return res.body;
  } else {
    // handle error here
    throw new Error('listTaskValidations failed');
  }
};

export const updateTokenRewardTransaction = async ({
  id,
  subdomain,
  data,
}: {
  id: string;
  subdomain: string;
  data: UpdateTokenRewardTransactionBodyInput;
}) => {
  const res = await apiV2.tokenReward.updateTokenRewardTransaction({
    params: { subdomain, tokenRewardId: id },
    body: data,
  });

  if (res.status === 200) {
    return res.body;
  }
  if (res.status === 400) {
    throw new AppError(res.body.message, res.body.context);
  } else {
    throw new AppError('updateTokenRewardTransaction failed');
  }
};
