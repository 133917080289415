import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import type { ListCommunitySecretOutput, ListPromotionBookingOutput } from '@zealy/schemas';
import { getSubdomain } from '@zealy/utils';

import { communityKeys } from '../communities.keys';
import { listCommunitySecrets } from '../communities.service';

export const useCommunitySecrets = ({
  subdomain = getSubdomain(),
  ...options
}: Partial<UseQueryOptions<ListCommunitySecretOutput>> & {
  subdomain?: string;
} = {}) => {
  return useQuery({
    ...options,
    queryKey: communityKeys.listCommunitySecrets(subdomain),
    queryFn: () => listCommunitySecrets(subdomain),
  });
};
