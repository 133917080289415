import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import { claimQuest, questKeys } from '..';

/**
 * Hook for claim a quest
 */
export const useClaimQuest = (moduleId: string, questId: string) => {
  const subdomain = getSubdomain();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: claimQuest(subdomain, questId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: questKeys.board(subdomain) });
    },
  });
};
