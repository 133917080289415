import { useQuery } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import { getInvitation } from '../communities.service';

export const useInvitation = ({
  id,
  subdomain = getSubdomain(),
}: {
  id: string | null;
  subdomain?: string;
}) => {
  return useQuery({
    queryKey: ['invitation', id],
    queryFn: () => getInvitation({ id: id ?? '', subdomain }),
    enabled: !!id,
  });
};
