import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import type { Community } from '../communities.types';
import type { CommunityCategory } from '@zealy/utils';
import { communityKeys } from '../communities.keys';
import { getExploreCommunities } from '../communities.service';

interface ExploreCommunities {
  results: Community[];
  nextPage: number;
  hasNextPage: boolean;
  totalCommunities: number;
}

export const useInfiniteCommunities = (
  filter: { category?: CommunityCategory; search?: string; endpoint?: string; web2?: boolean },
  limit = 30,
  initialData?: ExploreCommunities,
) => {
  return useInfiniteQuery({
    queryKey: communityKeys.explore(filter, limit),
    queryFn: ({ pageParam }) => {
      return getExploreCommunities({
        ...filter,
        pageParam,
        limit,
      });
    },

    getNextPageParam: lastPage => {
      if (lastPage.hasNextPage) {
        return lastPage.nextPage;
      }
      return undefined;
    },
    retry: 3,
    placeholderData: keepPreviousData,
    initialPageParam: 0,
    initialData: initialData
      ? {
          pages: [initialData],
          pageParams: [0],
        }
      : undefined,
  });
};

export type { CommunityCategory };
