import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getMe } from '../../services/utils';
import { apiKeyKeys } from '../apiKeys.keys';
import { deleteApiKey } from '../apiKeys.service';

export const useRevokeAPIKey = (userId = getMe()) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (apiKeyId: string) => deleteApiKey(userId ?? '', apiKeyId),
    onSuccess: data => {
      if (data.result) queryClient.invalidateQueries({ queryKey: apiKeyKeys.keys(userId) });
    },
  });
};
