import { useMutation } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import { claimNFT } from '../communities.service';

type MutationPrams = {
  address: string;
  nftId: string | number;
};

export const useClaimNFTReward = (questId: string) => {
  const subdomain = getSubdomain();
  return useMutation({
    mutationFn: (params: MutationPrams) =>
      claimNFT(subdomain, params.address, questId, params.nftId),
  });
};
