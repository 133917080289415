import { useQuery } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import type { GetMembersInput, GetMembersOutput } from '../members.service';
import { membersKeys } from '../members.keys';
import { getMembers } from '../members.service';

/**
 * Gets members with a role of reviewer and above
 */
export const useMembers = (
  subdomain = getSubdomain(),
  options?: GetMembersInput,
  initialData?: GetMembersOutput,
  enabled = true,
) => {
  return useQuery({
    queryKey: membersKeys.members(subdomain, options),
    queryFn: async () => getMembers(subdomain, options),
    initialData,
    enabled: !!subdomain && subdomain !== 'root' && enabled,
  });
};
