import { useMutation, useQueryClient } from '@tanstack/react-query';

import { communityKeys } from '../communities.keys';
import { createCommunity } from '../communities.service';
import { CreateCommunity } from '../communities.types';

/**
 * Hook for creating a community
 * @example ```
 * const { mutate, isLoading } = useCreateCommunity();
 * mutate({ name: 'My Community', blockchain: 'ethereum', description: 'My Community Description', image: File });
 * ```
 */
export const useCreateCommunity = (utmParams?: Record<string, string>) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CreateCommunity) => createCommunity(data, utmParams),
    onSuccess: data => {
      queryClient.setQueryData(communityKeys.community(data.subdomain), data);
    },
  });
};
