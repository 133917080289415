import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { UpdateTokenRewardTransactionBodyInput } from '@zealy/schemas';
import type { PatchQuestInput, PatchQuestOutput } from '@zealy/utils';
import { getSubdomain } from '@zealy/utils';

import { questKeys } from '../quests.keys';
import { updateQuest, updateTokenRewardTransaction } from '../quests.service';

/**
 * Hook for updating a token reward
 * @example ```
 * const { mutate, isPending } = useUpdateTokenRewardTransaction();
 * ```
 */
export const useUpdateTokenRewardTransaction = ({
  id,
  subdomain,
  questId,
}: {
  id: string;
  subdomain: string;
  questId: string;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: UpdateTokenRewardTransactionBodyInput) =>
      updateTokenRewardTransaction({ id, subdomain, data }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: questKeys.questRewards(subdomain, questId) });
    },
  });
};
