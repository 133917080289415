import { useMutation } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import { communityKeys } from '../communities.keys';
import { reportCommunity } from '../communities.service';

/**
 * Mutation hook for reporting a given community
 */
export function useReportCommunity() {
  const subdomain = getSubdomain();

  return useMutation({
    mutationKey: communityKeys.reportCommunity(subdomain),
    mutationFn: (reason: string) => reportCommunity(subdomain, reason),
  });
}
