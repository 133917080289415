import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import { communityKeys } from '../communities.keys';
import { unlinkCommunityAccount } from '../communities.service';

/**
 * Hook for unlinking a community account
 * @example ```
 * const { mutate, isLoading } = useUnlinkCommunityAccount();
 * mutate({
 *    accountType: 'twitter',
 * });
 * ```
 */
export const useUnlinkCommunityAccount = (subdomain?: string) => {
  const domain = subdomain || getSubdomain();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ accountType = 'twitter' }: { accountType?: 'twitter' | 'discord' }) =>
      unlinkCommunityAccount(domain, accountType),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: communityKeys.communityExternalAccounts(domain) });
    },
  });
};
