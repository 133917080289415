import { useMutation } from '@tanstack/react-query';

import type { GetQuestOutput } from '@zealy/utils';
import { getSubdomain } from '@zealy/utils';

import { testClaimQuest } from '..';

export const useTestClaimQuest = ({
  tasks,
  questId,
  subdomain = getSubdomain(),
}: {
  tasks: GetQuestOutput['tasks'];
  questId: string;
  subdomain?: string;
}) => {
  return useMutation({
    mutationFn: testClaimQuest(subdomain, questId, tasks),
  });
};
