import { api } from '../services/api';

export type OpenGraphData = {
  title: string;
  description: string;
  image: string;
  url: string;
  favicon: string;
};

export const getOpenGraphMetadata = (url: string) =>
  api.get<OpenGraphData>('/external/opengraph', {
    query: {
      url,
    },
  });
