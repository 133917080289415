import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { CreatePartnershipRequestInput } from '@zealy/schemas';
import { getSubdomain } from '@zealy/utils';

import { partnershipKeys } from '../partnership.keys';
import { createPartnership } from '../partnership.service';

type UseCreatePartnershipArgs = {
  subdomain: string;
};

export const useCreatePartnership = ({ subdomain = getSubdomain() }: UseCreatePartnershipArgs) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CreatePartnershipRequestInput) =>
      createPartnership(subdomain, data.message, data.targetedCommunityId, data.contact),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: partnershipKeys.genericKey(subdomain),
      });

      queryClient.invalidateQueries({
        queryKey: partnershipKeys.invalidateSearchPartnershipCommunities(subdomain),
      });
    },
  });
};
