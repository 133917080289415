import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import type { GetGuildOutput } from '@zealy/utils';
import { getSubdomain } from '@zealy/utils';

import { discordKeys } from '../discord.keys';
import { getGuildInfo } from '../discord.service';

/**
 * Gets the guild info for the given subdomain
 * ! need to check so there is a connected guild before calling this
 */
export const useGuildInfo = (
  subdomain = getSubdomain(),
  options?: UseQueryOptions<
    GetGuildOutput | undefined,
    unknown,
    GetGuildOutput | undefined,
    string[]
  >,
) => {
  return useQuery({
    ...options,
    queryKey: discordKeys.guildInfo(subdomain),
    queryFn: async () => getGuildInfo(subdomain),
    enabled: !!subdomain && subdomain !== 'root' && options?.enabled !== false,
  });
};
