import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import { membersKeys } from '../members.keys';
import { deleteMember } from '../members.service';

export const useDeleteMember = (userId: string, subdomain = getSubdomain()) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => deleteMember({ subdomain, userId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: membersKeys.members(subdomain) });
    },
  });
};
