import type { FetchOptions } from 'ofetch';

import type {
  CreateModuleInputSchemaType,
  ModuleSchemaType,
  UpdateModuleInputSchemaType,
} from '@zealy/utils';

import { api } from '../services/api';

export const getModule = (subdomain: string, moduleId: string, options?: FetchOptions) =>
  api.get<ModuleSchemaType>(`/communities/${subdomain}/modules/${moduleId}`, options);

export const getModules = (subdomain: string, options?: FetchOptions) =>
  api.get<ModuleSchemaType[]>(`/communities/${subdomain}/modules`, options);

export const createModule = ({
  subdomain,
  data,
}: {
  subdomain: string;
  data: CreateModuleInputSchemaType;
}) =>
  api.post<ModuleSchemaType>(`/communities/${subdomain}/modules`, {
    body: JSON.stringify(data),
  });

export const updateModule = ({
  subdomain,
  moduleId,
  data,
}: {
  subdomain: string;
  moduleId: string;
  data: UpdateModuleInputSchemaType;
}) =>
  api.patch<UpdateModuleInputSchemaType>(`/communities/${subdomain}/modules/${moduleId}`, {
    body: JSON.stringify(data),
  });

export const deleteModule = ({ subdomain, moduleId }: { subdomain: string; moduleId: string }) =>
  api.delete(`/communities/${subdomain}/modules/${moduleId}`);

export const duplicateModule = ({ subdomain, moduleId }: { subdomain: string; moduleId: string }) =>
  api.post<UpdateModuleInputSchemaType>(`/communities/${subdomain}/modules/${moduleId}/duplicate`);

export const reorderModules = ({
  subdomain,
  moduleIds,
}: {
  subdomain: string;
  moduleIds: string[];
}) =>
  api.patch<ModuleSchemaType[]>(`/communities/${subdomain}/modules/reorder`, {
    body: {
      moduleIds,
    },
  });
