import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import type { GetAdminQuestboardInput, GetAdminQuestboardOutput } from '@zealy/utils';
import { getSubdomain } from '@zealy/utils';

import { questKeys } from '../quests.keys';
import { getAdminQuestboard } from '../quests.service';

/**
 * Queries a community questboard and returns the admin data
 * @param subdomain The subdomain of the community to query, @default subdomain will be inferred from the URL
 * @param initialData The initial data to return
 * @example const { data, isLoading } = useAdminQuestboard('subdomain');
 */
export const useAdminQuestboard = ({
  subdomain = getSubdomain(),
  initialData,
  options,
  filters,
}: {
  subdomain?: string;
  initialData?: GetAdminQuestboardOutput;
  options?: Pick<UseQueryOptions, 'enabled' | 'retry'>;
  filters?: GetAdminQuestboardInput['filters'];
} = {}) => {
  return useQuery({
    queryKey: questKeys.admin.board(subdomain, filters),
    queryFn: async () =>
      getAdminQuestboard(subdomain, {
        query: { filters },
      }),
    initialData,
    ...options,
  });
};
