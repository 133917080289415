import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { getMe } from '../../services/utils';
import { npsKeys } from '../nps.keys';
import { getLastNPSScore } from '../nps.service';

export const useLastNPS = ({
  userId = getMe(),
  ...options
}: Partial<UseQueryOptions<Awaited<ReturnType<typeof getLastNPSScore>>>> & {
  userId?: string;
}) => {
  return useQuery({
    ...options,
    queryKey: npsKeys.lastScore(),
    queryFn: async () => getLastNPSScore(userId ?? 'me'),
  });
};
