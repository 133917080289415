import { useQuery } from '@tanstack/react-query';

import type { GetAdminQuestOutput } from '@zealy/utils';
import { getSubdomain } from '@zealy/utils';

import { questKeys } from '../quests.keys';
import { getAdminQuest } from '../quests.service';

/**
 * Queries a community Quest by its ID
 * @param id The ID of the Quest to query
 * @param subdomain The subdomain of the community to query, @default subdomain will be inferred from the URL
 * @param initialData The initial data to return
 * @example const { data, isLoading } = useAdminQuest('1', 'subdomain');
 */
export const useAdminQuest = (
  id: string,
  subdomain = getSubdomain(),
  initialData?: GetAdminQuestOutput,
  enabled = true,
) => {
  return useQuery({
    queryKey: questKeys.admin.quest(subdomain, id),
    queryFn: async () => getAdminQuest(subdomain, id),
    initialData,
    enabled: !!id && id !== 'new' && enabled,
  });
};
