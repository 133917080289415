import type { UseQueryOptions } from '@tanstack/react-query';

export type RQOptions<T extends (...args: any) => any> = Partial<
  UseQueryOptions<Awaited<ReturnType<T>>>
>;

export class AppError extends Error {
  public readonly context: Record<string, any>;
  constructor(message: string, context: Record<string, any> = {}) {
    super(message);
    this.context = context;
    this.name = this.constructor.name;
  }
}
