import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { discordUrlValidation } from '@zealy/utils';

import type { DiscordGuildInfo } from '../quests.service';
import { questKeys } from '../quests.keys';
import { getDiscordMetadata } from '../quests.service';

export const useDiscordMetadata = (
  inviteLink: string,
  options?: UseQueryOptions<
    DiscordGuildInfo | undefined,
    unknown,
    DiscordGuildInfo | undefined,
    string[]
  >,
) => {
  return useQuery({
    ...options,
    queryKey: questKeys.metadata('discord', inviteLink),
    queryFn: () => getDiscordMetadata(inviteLink),
    enabled: !!inviteLink && discordUrlValidation(inviteLink),
    retry: false,
  });
};
