import type {
  GetFailedAutomationsOutput,
  GetGuildOutput,
  PostDiscordIntegrationInput,
} from '@zealy/utils';

import { api } from '../services';

export type {
  GetFailedAutomationsOutput,
  GetGuildOutput,
  PostDiscordIntegrationInput,
} from '@zealy/utils';

interface WebhookEvent {
  name: string;
  active: boolean;
}

export interface Integration {
  id: string;
  communityId: string;
  name: string;
  webhookId?: string;
  active: boolean;
  events: WebhookEvent[];
}

export const getIntegration = async (subdomain: string) =>
  api.get<Integration[]>(`/communities/${subdomain}/plugins`);

export const disconnectIntegration = async (subdomain: string, name: string) =>
  api.delete(`/communities/${subdomain}/plugins/${name}`);

export const getGuildInfo = async (subdomain: string) =>
  api.get<GetGuildOutput>(`/communities/${subdomain}/plugins/discord/guild`);

export const updateDiscordIntegration = async (
  subdomain: string,
  options: PostDiscordIntegrationInput,
) =>
  api.post(`/communities/${subdomain}/plugins/discord/update`, {
    body: JSON.stringify(options),
  });

export const getFailedAutomations = async (subdomain: string) =>
  api.get<GetFailedAutomationsOutput>(`/communities/${subdomain}/failed-discord-integration`);

export const resendFailedDiscordRoles = async (subdomain: string) =>
  api.post(`/communities/${subdomain}/resend-failed-discord-roles`);
