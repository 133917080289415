import { useQuery } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import { leaderboardKeys } from '../leaderboard.keys';
import { getSprints } from '../leaderboard.service';

/**
 * Fetches the current sprints for a community
 */
export function useCurrentSprint(enabled = true) {
  const subdomain = getSubdomain();

  return useQuery({
    queryKey: leaderboardKeys.sprints(subdomain, 'current'),
    queryFn: async () => {
      const result = await getSprints(subdomain, {
        query: {
          onlyCurrent: true,
        },
      });

      return result?.[0] ?? null;
    },
    enabled: subdomain !== 'root' && enabled,
    staleTime: 30000,
  });
}
