import { useQuery } from '@tanstack/react-query';

import type { GetQuestResultOutput } from '@zealy/utils';
import { getSubdomain } from '@zealy/utils';

import { questKeys } from '../quests.keys';
import { getQuestResult } from '../quests.service';

/**
 * Queries quest results
 * @param id The ID of the Quest to query
 * @param subdomain The subdomain of the community to query, @default subdomain will be inferred from the URL
 * @param initialData The initial data to return
 * @example const { data, isLoading } = useQuestResult('1', 'subdomain');
 */
export const useQuestResult = (
  id: string,
  subdomain = getSubdomain(),
  initialData?: GetQuestResultOutput,
) => {
  return useQuery({
    queryKey: questKeys.results(subdomain, id),
    queryFn: async () => getQuestResult(subdomain, id),
    initialData,
    enabled: !!id,
  });
};
