import { useQuery } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import type { RQOptions } from '../../types';
import { analyticsKeys } from '../analytics.keys';
import { getTopCommunities } from '../analytics.service';
import { FiveMinutes, InitialRetryDelay } from '../constants';

export type UseTopCommunitiesArgs = { subdomain?: string; startDate: Date; endDate: Date };

export const useTopCommunities = ({
  subdomain = getSubdomain(),
  startDate,
  endDate,
  ...options
}: UseTopCommunitiesArgs & RQOptions<typeof getTopCommunities>) => {
  const q = useQuery({
    refetchInterval: query => (!query.state.data?.isComputed ? InitialRetryDelay : false),
    staleTime: FiveMinutes,
    ...options,
    queryKey: analyticsKeys.topCommunities(subdomain, startDate, endDate),
    queryFn: () => getTopCommunities(subdomain, startDate, endDate),
  });

  return { ...q, isPending: q.isPending || !q.data?.isComputed };
};
