import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { CreatePromotionBookingInput } from '@zealy/schemas';
import { getSubdomain } from '@zealy/utils';

import { communityKeys } from '../communities.keys';
import { createCommunitySecret } from '../communities.service';

export function useGenerateCommunitySecret() {
  const queryClient = useQueryClient();
  const domain = getSubdomain();
  return useMutation({
    mutationFn: () => createCommunitySecret(domain),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: communityKeys.listCommunitySecrets(domain) });
    },
  });
}
