import { useQuery } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import type { CommunityMember, User } from '../users.service';
import { usersKeys } from '../users.keys';
import { getCommunityMember, getUser, isUserConnected } from '../users.service';

/**
 * Will return the general user data and data relevant for community if
 * subdomain is defined or possible to extract from the location path
 * @param subdomain of community to get user data from
 * @returns User object with role set if subdomain is defined
 */

export const useAuthenticatedUser = <T extends User | CommunityMember>(
  subdomain?: string,
  initialData?: T,
  enabled?: boolean,
) => {
  const fetchData = async (subdomain?: string): Promise<T | undefined> => {
    if (subdomain && subdomain !== 'root' && subdomain !== '_') {
      // Fetch community member data
      return getCommunityMember('me', subdomain) as Promise<T>;
    } else {
      // Fetch general user data
      return getUser('me') as Promise<T>;
    }
  };

  const communityDomain = subdomain ?? getSubdomain();

  return useQuery({
    queryKey: usersKeys.user('me', communityDomain),
    queryFn: () => fetchData(communityDomain),
    initialData,
    enabled: isUserConnected(),
  });
};
