import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import { communityKeys } from '../../communities/communities.keys';
import { usersKeys } from '../../users/users.keys';
import { leaveCommunity } from '../communities.service';

/**
 * Mutation hook for leaving a community
 */
export function useLeaveCommunity(subdomain?: string) {
  const domain = subdomain || getSubdomain();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => leaveCommunity(domain, id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: usersKeys.user() });
      await queryClient.invalidateQueries({ queryKey: communityKeys.userCommunities() });
    },
  });
}
