import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { UpdatePartnershipRequestInput } from '@zealy/schemas';
import { getSubdomain } from '@zealy/utils';

import { partnershipKeys } from '../partnership.keys';
import { updatePartnershipStatus } from '../partnership.service';

export const useUpdatePartnershipStatus = (partnershipId: string, subdomain = getSubdomain()) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ status }: UpdatePartnershipRequestInput) =>
      updatePartnershipStatus(partnershipId, subdomain, status),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: partnershipKeys.genericKey(subdomain),
      });
      queryClient.invalidateQueries({
        queryKey: partnershipKeys.searchPartnershipCommunities(subdomain, ''),
      });
    },
  });
};
