import { useQuery } from '@tanstack/react-query';

import type { GetQuestOutput } from '@zealy/utils';
import { getSubdomain } from '@zealy/utils';

import { questKeys } from '../quests.keys';
import { getQuest } from '../quests.service';

/**
 * Queries a community Quest by its ID
 * @param id The ID of the Quest to query
 * @param subdomain The subdomain of the community to query, @default subdomain will be inferred from the URL
 * @param initialData The initial data to return
 * @example const { data, isLoading } = useQuest('1', 'subdomain');
 */
export const useQuest = (
  questId: string | undefined,
  subdomain = getSubdomain(),
  moduleId?: string,
  initialData?: GetQuestOutput,
  enabled = true,
) => {
  return useQuery({
    queryKey: questKeys.quest(subdomain, questId ?? ''),
    queryFn: async () => getQuest(subdomain, questId ?? ''),
    enabled: !!subdomain && subdomain !== 'root' && !!questId && enabled,
    initialData,
  });
};
