import type { FetchOptions } from 'ofetch';

import type { UserRole } from '@zealy/utils';

import { api } from '../services/api';

export interface MemberSchemaType {
  userId: string;
  id: string;
  role: UserRole;
  createdAt: string;
  updatedAt: string;
  joinDate: string;
  name: string;
  avatar: string;
}

export interface GetMembersOutput {
  members: MemberSchemaType[];
  totalCount: number;
}

export interface GetMembersInput {
  query?: {
    sortBy?: 'joinDate' | 'name' | 'role' | 'xp';
    orderBy?: 'desc' | 'asc';
    limit?: number;
    page?: number;
    search?: string;
    role?: UserRole | UserRole[];
    ids?: string[];
  };
}

export const getMembers = (
  subdomain: string,
  {
    query = {
      sortBy: 'role',
      orderBy: 'desc',
      role: ['admin', 'editor', 'reviewer'],
    },
    ...options
  }: GetMembersInput & FetchOptions = {},
) =>
  api.get<GetMembersOutput>(`/communities/${subdomain}/members`, {
    query,
    ...options,
  });

export const updateRole = ({
  subdomain,
  userId,
  role,
}: {
  subdomain: string;
  userId: string;
  role: UserRole;
}) =>
  api.post<MemberSchemaType>(`/communities/${subdomain}/members/change-role`, {
    body: JSON.stringify({ role, memberId: userId }),
  });

export const deleteMember = ({ subdomain, userId }: { subdomain: string; userId: string }) =>
  api.delete<MemberSchemaType>(`/communities/${subdomain}/members/${userId}`);
