import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { PartnershipSettingsSchemaInput } from '@zealy/schemas';
import { getSubdomain } from '@zealy/utils';

import { communityKeys } from '../../communities';
import { updatePartnershipSettings } from '../partnership.service';

export const usePartnershipSettingsMutation = (subdomain = getSubdomain()) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: PartnershipSettingsSchemaInput) =>
      updatePartnershipSettings(
        subdomain,
        data.partnershipsEnabled,
        data.partnershipsRequiredMemberCount,
      ),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: communityKeys.community(subdomain),
      }),
  });
};
