import { useQuery } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import { leaderboardKeys } from '../leaderboard.keys';
import { getSprints } from '../leaderboard.service';

/**
 * Fetches the current sprints for a community
 */
export function useSprints({ enabled = true } = {}) {
  const subdomain = getSubdomain();

  return useQuery({
    queryKey: leaderboardKeys.sprints(subdomain),
    queryFn: async () => getSprints(subdomain),
    enabled: !!subdomain && subdomain !== 'root' && enabled,
    staleTime: 30000,
    retry: 1,
  });
}
