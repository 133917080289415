import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import { leaderboardKeys } from '../../leaderboard';
import { questKeys } from '../../quests';
import { reviewsKeys } from '../reviews.keys';
import { resetClaims } from '../reviews.service';

export const useResetClaims = (userId?: string, subdomain = getSubdomain()) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (questId?: string) => resetClaims(subdomain, questId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: reviewsKeys.list(subdomain) });
      queryClient.invalidateQueries({ queryKey: questKeys.board(subdomain) });
      queryClient.invalidateQueries({ queryKey: leaderboardKeys.sprints(subdomain) });
      queryClient.invalidateQueries({ queryKey: leaderboardKeys.leaderboard(subdomain) });
      queryClient.invalidateQueries({ queryKey: leaderboardKeys.rank(subdomain, userId!) });
    },
  });
};
