import { useQuery } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import { getCommunitySubscriptionStatus } from '../subscription.service';
import { subscriptionsKeys } from '../subscriptions.keys';

export const useSubscriptionStatus = (options?: { enabled?: boolean }) => {
  const subdomain = getSubdomain();
  return useQuery({
    queryKey: subscriptionsKeys.subscriptionStatus(subdomain),
    queryFn: () => getCommunitySubscriptionStatus(subdomain),
    ...(options ?? {}),
    staleTime: 1000 * 60 * 60, // 1 hour
  });
};
