import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { PatchSprintsInput } from '@zealy/utils';
import { getSubdomain } from '@zealy/utils';

import { leaderboardKeys } from '../leaderboard.keys';
import { updateSprint } from '../leaderboard.service';

export const useUpdateSprint = (sprintId: string | undefined, subdomain = getSubdomain()) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: PatchSprintsInput) => updateSprint(subdomain, sprintId ?? '', data),
    onSuccess: data => {
      queryClient.setQueryData(leaderboardKeys.sprints(subdomain), data);
    },
  });
};
