import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { GetReviewOutput } from '@zealy/schemas';
import { getSubdomain } from '@zealy/utils';

import { reviewsKeys } from '../reviews.keys';
import { cancelReview } from '../reviews.service';

export const useCancelReview = (subdomain = getSubdomain(), featureFlag?: boolean) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (ids: string[]) => cancelReview(subdomain, ids, featureFlag),
    onSuccess: (data, ids) => {
      // when removing the feature flag, only use data.reviewedClaimQuestIds
      const claimedQuestIds: string[] = featureFlag
        ? data.reviewedClaimQuestIds
        : data.map(({ id }: { id: string }) => id);

      claimedQuestIds.forEach(claimId => {
        queryClient.invalidateQueries({
          queryKey: reviewsKeys.events(subdomain, claimId),
        });
        queryClient.setQueryData(
          reviewsKeys.claim(subdomain, claimId),
          (prev: GetReviewOutput | undefined) =>
            prev
              ? {
                  ...prev,
                  status: 'pending' as const,
                }
              : undefined,
        );
      });

      queryClient.setQueryData(reviewsKeys.pendingCount(subdomain), (prev: number | undefined) => {
        if (typeof prev !== 'undefined') {
          return prev + ids.length;
        }
        return prev;
      });
    },
  });
};
