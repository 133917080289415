import { InfiniteData, keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import type { Community } from '../communities.types';
import { isUserConnected } from '../../users/users.service';
import { communityKeys } from '../communities.keys';
import { getUserCommunities } from '../communities.service';

export interface CommunityResults {
  results: Community[];
  nextPage: 2;
  hasNextPage: true;
}

/**
 * Gets an authenticated user's communities
 * @example ```
 * const { data, isLoading } = useUserCommunities();
 * console.log(data)
 * ```
 */
export const useUserCommunities = (search?: string, initialData?: CommunityResults) => {
  return useInfiniteQuery({
    queryKey: communityKeys.userCommunities(search),
    queryFn: ({ pageParam }) => getUserCommunities({ pageParam, search }),

    getNextPageParam: lastPage => {
      if (lastPage.hasNextPage) {
        return lastPage.nextPage;
      }
      return undefined;
    },
    placeholderData: keepPreviousData,
    initialData: initialData
      ? {
          pages: [initialData],
          pageParams: [1],
        }
      : undefined,
    enabled: isUserConnected(),
    retry: 3,
    initialPageParam: 0,
  });
};

export { Community };
