import { useQuery } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import type { RQOptions } from '../../types';
import { analyticsKeys } from '../analytics.keys';
import { getTopCountries } from '../analytics.service';
import { FiveMinutes, InitialRetryDelay } from '../constants';

export type UseTopCountriesArgs = {
  subdomain?: string;
  startDate: Date;
  endDate: Date;
};

export const useTopCountries = ({
  subdomain = getSubdomain(),
  startDate,
  endDate,
  ...options
}: UseTopCountriesArgs & RQOptions<typeof getTopCountries>) => {
  const q = useQuery({
    refetchInterval: query => (!query.state.data?.isComputed ? InitialRetryDelay : false),
    staleTime: FiveMinutes,
    ...options,
    queryKey: analyticsKeys.topCountries(subdomain, startDate, endDate),
    queryFn: () => getTopCountries(subdomain, startDate, endDate),
  });

  return { ...q, isPending: q.isPending || !q.data?.isComputed };
};
