import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import type { BlockchainNetwork } from '@zealy/utils';

import { blockchainKeys } from '../blockchain.keys';
import { getNFTCollectionMetadata } from '../blockchain.service';

export const useNFTCollectionMetadata = (
  {
    contract,
    network,
  }: {
    contract: string;
    network: BlockchainNetwork;
  },
  options?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getNFTCollectionMetadata>>>>,
) => {
  return useQuery({
    ...options,
    queryKey: blockchainKeys.nftCollection(network, contract),
    queryFn: () => getNFTCollectionMetadata({ contract, network }),
    retry: false,
  });
};
