import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getMe } from '../../services/utils';
import { apiKeyKeys } from '../apiKeys.keys';
import { postApiKey } from '../apiKeys.service';

export const useCreateAPIKey = (userId = getMe()) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (writeAccess: boolean) => postApiKey(userId ?? '', writeAccess),
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: apiKeyKeys.keys(userId) });
    },
  });
};
