import { useCommunity } from '../../communities';
import { useAuthenticatedUser } from './useAuthenticatedUser';

/**
 * From figma designs, the intention of user has multiple
 * addresses in a community seems not possible. For now, to
 * keep the UI consistent we will only use the first address but
 * return as an array.
 *
 * @todo update this query to return all available address for
 * an user in a community.
 */
export const useUserWalletAddresses = () => {
  const community = useCommunity();
  const communityBlockchain = community.data?.blockchain || 'ethereum';
  const { data } = useAuthenticatedUser();
  return [data?.addresses?.[communityBlockchain]].filter(address => !!address) as string[];
};
