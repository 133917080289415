import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import type { TelegramGroupInfo } from '../quests.service';
import { questKeys } from '../quests.keys';
import { getTelegramMetadata } from '../quests.service';

export const useTelegramMetadata = (
  inviteLink: string,
  options?: UseQueryOptions<
    TelegramGroupInfo | undefined,
    unknown,
    TelegramGroupInfo | undefined,
    string[]
  >,
) => {
  return useQuery({
    ...options,
    queryKey: questKeys.metadata('telegram', inviteLink),
    queryFn: () => getTelegramMetadata(inviteLink),
    enabled: !!inviteLink && inviteLink.startsWith('https://t.me/'),
    retry: false,
  });
};
