import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import type { Activity } from '../users.service';
import { usersKeys } from '../users.keys';
import { getActivity, isUserConnected } from '../users.service';

export interface ActivityData {
  results: Activity[];
  nextPage: number;
  hasNextPage: boolean;
}

/**
 * Returns the activity of a user in a community
 * @param subdomain of community to get user data from
 *
 * @example
 * ```ts
 * const { data, isLoading, isFetching, fetchNextPage } = useActivity(userId, subdomain, initialData);
 *
 * const activity = data?.pages.flatMap((page) => page.results);
 * ```
 */
export const useActivity = (
  userId = 'me',
  subdomain = getSubdomain(),
  initialData?: ActivityData,
) => {
  return useInfiniteQuery({
    queryKey: usersKeys.activity(userId, subdomain),
    queryFn: ({ pageParam }) => getActivity({ userId, subdomain, page: pageParam }),

    getNextPageParam: lastPage => {
      if (lastPage.hasNextPage) {
        return lastPage.nextPage;
      }
      return undefined;
    },
    placeholderData: keepPreviousData,
    initialData: {
      pages: [initialData ?? { results: [], nextPage: 1, hasNextPage: true }],
      pageParams: [],
    },
    enabled: isUserConnected(),
    retry: 3,
    initialPageParam: 0,
  });
};
