import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { getSubdomain, isValidSubdomain } from '@zealy/utils';

import { questKeys } from '../quests.keys';
import { getTemplates, listTemplateQuests } from '../quests.service';

/**
 * Queries a community questboard and returns the admin data
 * @param subdomain The subdomain of the community to query, @default subdomain will be inferred from the URL
 * @param initialData The initial data to return
 * @example const { data, isLoading } = useTemplates('subdomain');
 */
export const useTemplates = (
  subdomain = getSubdomain(),
  { enabled = true, ...options }: Pick<UseQueryOptions, 'enabled' | 'retry'> = {},
) => {
  return useQuery({
    queryKey: questKeys.templates(subdomain),
    queryFn: async () => listTemplateQuests(subdomain),
    enabled: isValidSubdomain(subdomain) && enabled,
    ...options,
  });
};
