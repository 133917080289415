import { useQuery } from '@tanstack/react-query';

import type { GetQuestOutput } from '@zealy/utils';
import { getSubdomain } from '@zealy/utils';

import { questKeys } from '../quests.keys';
import { getQuests } from '../quests.service';

/**
 * Queries a community quests by its ID
 * Used in the conditions & reviews filter
 * @param subdomain The subdomain of the community to query, @default subdomain will be inferred from the URL
 * @param initialData The initial data to return
 * @example const { data, isLoading } = useQuests('subdomain');
 */
export const useQuests = (
  subdomain = getSubdomain(),
  initialData?: GetQuestOutput[],
  enabled = true,
) => {
  return useQuery({
    queryKey: questKeys.quests(subdomain),
    queryFn: async () => getQuests(subdomain),
    initialData,
    enabled: !!subdomain && subdomain !== 'root' && enabled,
  });
};
