import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import type { CommunityMember } from '../users.service';
import { usersKeys } from '../users.keys';
import { updateUserRole } from '../users.service';

/**
 * Hook for changing a user's role in a community.
 * @example
 * ```ts
 * const updateRole = useUpdateRole({ userId, subdomain });
 * updateRole.mutate('banned');
 * ```
 */
export const useUpdateUserRole = ({
  userId,
  subdomain = getSubdomain(),
}: {
  userId: string;
  subdomain?: string;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (role: CommunityMember['role']) => updateUserRole(userId, subdomain, role),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: usersKeys.user(userId, subdomain),
      });
    },
  });
};
