import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { CreatePromotionBookingInput } from '@zealy/schemas';
import { getSubdomain } from '@zealy/utils';

import { updatePromotionBooking } from '../subscription.service';
import { subscriptionsKeys } from '../subscriptions.keys';

export function useUpdatePromotionBooking() {
  const queryClient = useQueryClient();
  const domain = getSubdomain();
  return useMutation({
    mutationFn: (body: CreatePromotionBookingInput) => updatePromotionBooking(domain, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: subscriptionsKeys.listPromotionBooking(domain) });
    },
  });
}
