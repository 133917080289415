import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import type { GenerateMagicQuestSuggestionsParams } from '../quests.service';
import { questKeys } from '../quests.keys';
import { generateMagicQuestSuggestions } from '../quests.service';

export const useMagicQuestSuggestions = ({
  useCase,
  description,
  subdomain = getSubdomain(),
  ...options
}: Partial<UseQueryOptions<Awaited<ReturnType<typeof generateMagicQuestSuggestions>>>> &
  GenerateMagicQuestSuggestionsParams & {
    subdomain?: string;
  }) => {
  return useQuery({
    ...options,
    queryKey: questKeys.admin.aiSuggestions(subdomain, useCase, description),
    queryFn: async () => generateMagicQuestSuggestions(subdomain, { useCase, description }),
  });
};
