import type { CommunityCategory } from '@zealy/utils';

import type { CommunityInviteOptions } from './communities.types';

export const communityKeys = {
  community: (subdomain: string) => ['community', subdomain],
  category: (category: string, limit: number, page: number) => [
    'communities',
    category,
    limit,
    page,
  ],
  twitterOAuthURL: (subdomain?: string) => ['community:twitter:auth', subdomain] as const,
  communityExternalAccounts: (subdomain?: string) =>
    ['community:twitter:verification', subdomain] as const,
  invite: (subdomain: string, options: CommunityInviteOptions) =>
    ['community:invite', subdomain, options] as const,
  userCommunities: (search?: string) =>
    !search?.length ? ['communities:user:all'] : ['communities:user:all', search],
  explore: (
    explore: { category?: CommunityCategory; search?: string; web2?: boolean },
    limit: number,
  ) => ['communities:explore', explore.category, explore.search, explore.web2, limit] as const,
  referralLink: (subdomain: string) => ['community:referral-link', subdomain] as const,
  reportCommunity: (subdomain: string) => ['community:report', subdomain] as const,
  roles: (subdomain: string) => ['community:roles', subdomain] as const,
  wallet: (subdomain: string) => ['community:wallet', subdomain] as const,
  failedDiscordRolesDelivery: (subdomain: string) => ['failedDiscordRolesDelivery', subdomain],
  onboarding: (subdomain: string) => ['community:onboarding', subdomain] as const,
  listCommunitySecrets: (subdomain: string) => ['community:secrets', subdomain] as const,
};
