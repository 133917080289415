import type { PartnershipStatus } from '@zealy/schemas';

export const partnershipKeys = {
  invalidateSearchPartnershipCommunities: (subdomain: string) => [
    'search-partnership-communities',
    subdomain,
  ],
  searchPartnershipCommunities: (subdomain: string, criteria: string) => [
    'search-partnership-communities',
    subdomain,
    criteria,
  ],
  genericKey: (subdomain: string) => ['list-partnerships', subdomain],
  listPartnerships: (
    subdomain: string,
    status: PartnershipStatus,
    position?: 'incoming' | 'requested',
  ) =>
    position
      ? ['list-partnerships', subdomain, status, position]
      : ['list-partnerships', subdomain, status],
};
