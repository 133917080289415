import type { GetSpaceOutput, Tweet, TwitterUser } from './twitter.types';
import { api } from '../services/api';

export const getTwitterSpaceInfo = async (id: string) => {
  return api.get<GetSpaceOutput>(`/external/twitter/space/${id}`);
};

export const getTwitterAccount = async (username: string) =>
  api.get<TwitterUser>(`/external/twitter/user/${username}`);

export const getTweet = async (id: string) => api.get<Tweet>(`/external/twitter/tweet/${id}`);
