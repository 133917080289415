import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { UserRole } from '@zealy/utils';
import { getSubdomain } from '@zealy/utils';

import { membersKeys } from '../members.keys';
import { updateRole } from '../members.service';

export const useUpdateRole = (userId: string, subdomain = getSubdomain()) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (role: UserRole) => updateRole({ subdomain, userId, role }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: membersKeys.members(subdomain) });
    },
  });
};
