import { useMutation, useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';

import { getSubdomain } from '@zealy/utils';

import { usersKeys } from '../users.keys';
import { deleteAuthenticatedUser, updateAuthenticatedUser } from '../users.service';

/**
 * @returns Hook for updating the authenticated user
 * @example const { mutate, isLoading } = useUpdateAuthenticatedUser();
 */
export const useUpdateAuthenticatedUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateAuthenticatedUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: usersKeys.user('me', getSubdomain()),
      });
    },
  });
};

export const useDeleteAuthenticatedUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteAuthenticatedUser,
    onSuccess: () => {
      Cookies.remove('user_metadata', {
        domain: `.${window.location.hostname}`,
      });
      queryClient.removeQueries();
    },
  });
};
