import { useMutation } from '@tanstack/react-query';

import { impersonateUser } from '../devTools.service';

export const useImpersonateUserMutation = () => {
  return useMutation({
    mutationFn: (email: string) => {
      return impersonateUser(email);
    },
  });
};
