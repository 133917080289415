import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { twitterKeys } from '../twitter.keys';
import { getTwitterSpaceInfo } from '../twitter.service';

export const useTwitterSpace = (url?: string, options?: Partial<UseQueryOptions>) => {
  const pathnames = (url && new URL(url).pathname.split('/')) || [];
  const id = pathnames.length ? pathnames[pathnames.length - 1] : '';

  return useQuery({
    queryKey: twitterKeys.spaces(id),
    queryFn: async () => getTwitterSpaceInfo(id),
    enabled: options?.enabled ?? !!id,
  });
};
