import type { Interval } from './types';

export const analyticsKeys = {
  membersOverTime: (subdomain: string, startDate: Date, endDate: Date, interval: Interval) => [
    'members-over-time',
    subdomain,
    startDate.toISOString(),
    endDate.toISOString(),
    interval,
  ],
  newMembers: (subdomain: string, startDate: Date, endDate: Date, interval: Interval) => [
    'new-members',
    subdomain,
    startDate.toISOString(),
    endDate.toISOString(),
    interval,
  ],
  twitterFollowers: (subdomain: string, startDate: Date, endDate: Date, interval: Interval) => [
    'twitter-followers',
    subdomain,
    startDate.toISOString(),
    endDate.toISOString(),
    interval,
  ],
  discordMembers: (subdomain: string, startDate: Date, endDate: Date, interval: Interval) => [
    'discord-members',
    subdomain,
    startDate.toISOString(),
    endDate.toISOString(),
    interval,
  ],
  mostActiveUsers: (subdomain: string, startDate: Date, endDate: Date, interval: Interval) => [
    'most-active-users',
    subdomain,
    startDate.toISOString(),
    endDate.toISOString(),
    interval,
  ],
  topCountries: (subdomain: string, startDate: Date, endDate: Date) => [
    'top-countries',
    subdomain,
    startDate.toISOString(),
    endDate.toISOString(),
  ],
  topCommunities: (subdomain: string, startDate: Date, endDate: Date) => [
    'top-communities',
    subdomain,
    startDate.toISOString(),
    endDate.toISOString(),
  ],
  topQuests: (subdomain: string, startDate: Date, endDate: Date) => [
    'top-quests',
    subdomain,
    startDate.toISOString(),
    endDate.toISOString(),
  ],
  topReviewers: (subdomain: string, startDate: Date, endDate: Date) => [
    'top-reviewsers',
    subdomain,
    startDate.toISOString(),
    endDate.toISOString(),
  ],
  claimedQuests: (
    subdomain: string,
    status: 'all' | 'pending' | 'success' | 'fail',
    startDate: Date,
    endDate: Date,
    interval: Interval,
  ) => [
    'claimed-quests',
    subdomain,
    status,
    startDate.toISOString(),
    endDate.toISOString(),
    interval,
  ],
  claimedQuestsStatusCount: (subdomain: string, startDate: Date, endDate: Date) => [
    'claimed-quests-status-count',
    subdomain,
    startDate.toISOString(),
    endDate.toISOString(),
  ],
  topReferrals: (subdomain: string, startDate: Date, endDate: Date) => [
    'top-referrals',
    subdomain,
    startDate.toISOString(),
    endDate.toISOString(),
  ],
};
