import type { QueryKey, UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import { communityKeys } from '../communities.keys';
import { getCommunityDiscordRoles } from '../communities.service';

type GetCommunityDiscordRolesResult = Awaited<ReturnType<typeof getCommunityDiscordRoles>>;

export const useCommunityDiscordRoles = (
  subdomain = getSubdomain(),
  options?: Partial<
    Pick<
      UseQueryOptions<
        GetCommunityDiscordRolesResult,
        Error,
        GetCommunityDiscordRolesResult,
        QueryKey
      >,
      'enabled' | 'retry' | 'refetchInterval'
    >
  >,
) => {
  return useQuery<GetCommunityDiscordRolesResult, Error>({
    queryKey: communityKeys.roles(subdomain),
    queryFn: () => getCommunityDiscordRoles(subdomain),
    enabled: !!subdomain && subdomain !== 'root',
    retry: false,
    ...options,
  });
};
