import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { getTwitterAccount } from '../twitter.service';

export const useTwitterAccount = (username: string, options?: Partial<UseQueryOptions>) =>
  useQuery({
    queryKey: ['twitter', username],
    queryFn: () => getTwitterAccount(username),
    enabled: options?.enabled ?? !!username,
  });
