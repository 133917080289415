import { useQuery } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import { getMe } from '../../services';
import { leaderboardKeys } from '../leaderboard.keys';
import { getRank } from '../leaderboard.service';

export function useRank(sprintId?: string, userId = getMe(), enabled = true) {
  const subdomain = getSubdomain();

  return useQuery({
    queryKey: leaderboardKeys.rank(subdomain, userId ?? 'me', sprintId ?? 'all'),
    queryFn: async () => getRank(subdomain, userId ?? 'me', sprintId ?? 'all'),
    enabled: !!userId && subdomain !== 'root' && enabled,
    staleTime: 30000,
  });
}
