import { useMutation, useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';

import { getSubdomain } from '@zealy/utils';

import { communityKeys } from '../../communities/communities.keys';
import { usersKeys } from '../../users/users.keys';
import { joinCommunity } from '../communities.service';

/**
 * Mutation hook for joining a community
 */
export function useJoinCommunity(subdomain?: string) {
  const domain = subdomain || getSubdomain();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => joinCommunity(domain),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: usersKeys.user(undefined, domain) });
      await queryClient.invalidateQueries({ queryKey: communityKeys.userCommunities() });
      Cookies.remove('referrer-url');
    },
  });
}
