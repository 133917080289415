import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import type { GetCRMViewOutput } from '@zealy/utils';
import { getSubdomain } from '@zealy/utils';

import { crmKeys } from '../crm.keys';
import { getView } from '../crm.service';

type UseCRMViewArgs = Partial<UseQueryOptions<GetCRMViewOutput>> & {
  subdomain?: string;
};

export const useCRMView = ({ subdomain = getSubdomain(), ...options }: UseCRMViewArgs = {}) => {
  return useQuery({
    ...options,
    queryKey: crmKeys.view(subdomain),
    queryFn: async () => getView({ path: { subdomain } }),
  });
};
