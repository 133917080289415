import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import { resumeSubscription } from '../subscription.service';
import { subscriptionsKeys } from '../subscriptions.keys';

/**
 * Mutation hook for changing your subscription plan
 */
export function useResumeSubscription() {
  const queryClient = useQueryClient();
  const domain = getSubdomain();
  return useMutation({
    mutationFn: () => resumeSubscription(domain),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: subscriptionsKeys.subscription(domain) });
      queryClient.invalidateQueries({ queryKey: subscriptionsKeys.pricingTable(domain) });
    },
  });
}
