// todo why do we do this ?
import '@zealy/utils';

import type { ApiFetcherArgs, ClientInferRequest, contract } from '@zealy/contracts';

import { apiV2 } from '../services';

export const getWebhookTypes = async (subdomain: string) => {
  const res = await apiV2.communityWebhook.listAvailableEventTypes({
    params: { subdomain },
  });

  if (res.status === 200) {
    return res.body;
  } else {
    // handle error here
    throw new Error('getWebhookTypes error');
  }
};

export const getWebhooks = async (subdomain: string, _next?: ApiFetcherArgs['next']) => {
  const res = await apiV2.communityWebhook.list({
    params: { subdomain },
    _next,
  });

  if (res.status === 200) {
    return res.body;
  } else {
    // handle error here
    throw new Error('getWebhooks error');
  }
};

export const listWebhookEvents = async (
  options: ClientInferRequest<typeof contract.communityWebhook.listEvents>,
) => {
  const res = await apiV2.communityWebhook.listEvents({
    ...options,
    cache: 'no-cache',
  });

  if (res.status === 200) {
    return res.body;
  } else {
    // handle error here
    throw new Error('listWebhookEvents error');
  }
};

export const getWebhookEvent = async (
  params: ClientInferRequest<typeof contract.communityWebhook.getEvent>['params'],
  _next?: ApiFetcherArgs['next'],
) => {
  const res = await apiV2.communityWebhook.getEvent({
    params,
    _next,
  });

  if (res.status === 200) {
    return res.body;
  } else {
    // handle error here
    throw new Error('getWebhookEvent error');
  }
};

export const createWebhook = async (
  subdomain: string,
  body: ClientInferRequest<typeof contract.communityWebhook.create>['body'],
) => {
  const res = await apiV2.communityWebhook.create({
    params: { subdomain },
    body,
  });

  if (res.status === 200) {
    return res.body;
  } else {
    // handle error here
    throw new Error('createWebhook error');
  }
};

export const updateWebhook = async (
  subdomain: string,
  id: string,
  body: ClientInferRequest<typeof contract.communityWebhook.update>['body'],
) => {
  const res = await apiV2.communityWebhook.update({
    params: { subdomain, webhookId: id },
    body,
  });

  if (res.status !== 200) {
    // handle error here
    throw new Error('updateWebhook error');
  }
};

export const deleteWebhook = async (subdomain: string, id: string) => {
  const res = await apiV2.communityWebhook.delete({
    params: { subdomain, webhookId: id },
  });

  if (res.status !== 200) {
    // handle error here
    throw new Error('deleteWebhook error');
  }
};

export const retryWebhookEvent = async (
  params: ClientInferRequest<typeof contract.communityWebhook.retryEvent>['params'],
) => {
  const res = await apiV2.communityWebhook.retryEvent({
    params,
  });

  if (res.status !== 200) {
    // handle error here
    throw new Error('retryWebhookEvent error');
  }
};

export const regenerateSecret = async (
  params: ClientInferRequest<typeof contract.communityWebhook.regenerateSecret>['params'],
) => {
  const res = await apiV2.communityWebhook.regenerateSecret({
    params,
  });

  if (res.status === 200) {
    return res.body;
  } else {
    // handle error here
    throw new Error('regenerateSecret error');
  }
};

export const sendTestEvent = async (
  options: ClientInferRequest<typeof contract.communityWebhook.testEvent>,
) => {
  const res = await apiV2.communityWebhook.testEvent(options);

  if (res.status === 200) {
    return res.body;
  } else {
    // handle error here
    throw new Error('sendTestEvent error');
  }
};
