import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import { getSubdomain, isValidSubdomain } from '@zealy/utils';

import type { ListNotificationsOutput } from '../notifications.service';
import { getMe } from '../../services';
import { isUserConnected } from '../../users';
import { notificationsKeys } from '../notifications.keys';
import { getNotifications } from '../notifications.service';

export const useInbox = (
  subdomain = getSubdomain(),
  userId = getMe(),
  initialData?: ListNotificationsOutput,
  enabled = true,
) => {
  return useInfiniteQuery({
    queryKey: notificationsKeys.notifications(subdomain, userId ?? 'me'),
    queryFn: ({ pageParam }) =>
      getNotifications({
        params: {
          userId: userId ?? 'me',
          subdomain,
        },
        query: {
          cursor: pageParam ?? undefined,
          limit: 20,
        },
      }),

    getNextPageParam: lastPage => lastPage.nextCursor,
    placeholderData: keepPreviousData,
    staleTime: 60000,
    enabled: isUserConnected() && isValidSubdomain(subdomain) && enabled,
    initialData: initialData
      ? {
          pages: [initialData],
          pageParams: [initialData.nextCursor],
        }
      : undefined,
    initialPageParam: undefined,
  });
};
