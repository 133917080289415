import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import { usersKeys } from '../users.keys';
import { banUser, unBanUser } from '../users.service';

export const useBanUser = (userId: string) => {
  const queryClient = useQueryClient();
  const subdomain = getSubdomain();
  return useMutation({
    mutationFn: ({ action, reason }: { action: 'ban' | 'unban'; reason?: string }) => {
      if (action === 'ban') {
        return banUser(subdomain, userId, { reason });
      } else {
        return unBanUser(subdomain, userId);
      }
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: usersKeys.user(userId, subdomain),
      });
    },
  });
};
