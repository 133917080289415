import { useMutation } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import { generateRedirectUrlForApiQuestTask } from '../auth.service';

export const useRedirectUrlApiQuestTask = ({
  subdomain = getSubdomain(),
  questId,
  taskId,
}: {
  subdomain?: string;
  questId: string;
  taskId: string;
}) => {
  return useMutation({
    mutationFn: async () => {
      return generateRedirectUrlForApiQuestTask({
        subdomain,
        questId,
        taskId,
      });
    },
  });
};
