import type { ListTaskValidationsSort } from '@zealy/schemas';

export const questKeys = {
  board: (subdomain: string, filters?: string[]) =>
    !filters ? [subdomain, 'quests'] : [subdomain, 'quests', filters],
  module: (subdomain: string, moduleId: string, filters?: string[]) =>
    !filters ? [subdomain, 'quests', moduleId] : [subdomain, 'quests', moduleId, filters],

  quests: (subdomain: string) => [subdomain, 'quests', 'list'],
  quest: (subdomain: string, questId: string) => [subdomain, 'quests', questId],

  admin: {
    board: (subdomain: string, filters?: any) =>
      filters ? [subdomain, 'quests', 'admin', filters] : [subdomain, 'quests', 'admin'],
    quests: (subdomain: string) => [subdomain, 'quests', 'admin'],
    quest: (subdomain: string, questId: string) => [subdomain, 'quests', questId, 'admin'],
    withClaim: (subdomain: string, startDate?: string) => [
      subdomain,
      'quests',
      'admin',
      'claim',
      startDate,
    ],
    aiSuggestions: (subdomain: string, useCase: string, description: string) => [
      subdomain,
      'ai',
      useCase,
      description,
    ],
  },

  results: (subdomain: string, questId: string) => [subdomain, 'quests', questId, 'results'],
  taskResults: (subdomain: string, questId: string) => [
    subdomain,
    'quests',
    questId,
    'tasks:results',
  ],
  templates: (subdomain: string) => [subdomain, 'templates'],
  metadata: (type: string, value: string) => ['metadata', type, value],
  questRewards: (subdomain: string, questId: string) => [subdomain, 'quests', questId, 'rewards'],
  taskValidations: (
    subdomain: string,
    questId: string,
    taskId: string,
    sortedBy: ListTaskValidationsSort,
  ) => [subdomain, 'quests', questId, 'tasks', taskId, 'validations', 'sortedBy', sortedBy],
};
