import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import type { GetTaskResultOutput } from '@zealy/utils';
import { getSubdomain } from '@zealy/utils';

import { questKeys } from '../quests.keys';
import { getTaskResult } from '../quests.service';

/**
 * Queries task results
 * @param id The ID of the task to query
 * @param subdomain The subdomain of the community to query, @default subdomain will be inferred from the URL
 * @param initialData The initial data to return
 * @example const { data, isLoading } = useTaskResults('1', 'text', 'subdomain');
 */
export const useTaskResult = <T extends 'text' | 'quiz' | 'file' | 'poll' | 'opinion'>(
  questId: string,
  id: string,
  subdomain = getSubdomain(),
) => {
  return useInfiniteQuery<{
    results: GetTaskResultOutput<T>;
    nextPage?: number;
    hasNextPage: boolean;
  }>({
    queryKey: questKeys.taskResults(subdomain, id),
    queryFn: ({ pageParam }) =>
      getTaskResult<T>(subdomain, questId, id, {
        query: {
          page: pageParam ?? 1,
        },
      }),

    getNextPageParam: ({ nextPage }) => nextPage,
    retry: 3,
    placeholderData: keepPreviousData,
    initialPageParam: 1,
  });
};
