import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import type { GetZealyConnectStatusOutput } from '@zealy/schemas';
import { getSubdomain } from '@zealy/utils';

import { getZealyConnectStatus } from '../auth.service';

export const useZealyConnectionStatus = ({
  subdomain = getSubdomain(),
  questId,
  ...options
}: Partial<UseQueryOptions<GetZealyConnectStatusOutput>> & {
  subdomain?: string;
  questId: string;
}) => {
  return useQuery({
    ...options,
    queryKey: ['zealy-connect', subdomain, questId],
    queryFn: () =>
      getZealyConnectStatus({
        questId,
        subdomain,
      }),
  });
};
