import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { CreateModuleInputSchemaType } from '@zealy/utils';
import { getSubdomain } from '@zealy/utils';

import { questKeys } from '../../quests/quests.keys';
import { moduleKeys } from '../modules.keys';
import { createModule } from '../modules.service';

/**
 * Hook for creating a module
 * @example ```
 * const { mutate, isLoading } = useCreateModule();
 * ```
 */
export const useCreateModule = (subdomain = getSubdomain()) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CreateModuleInputSchemaType) => createModule({ subdomain, data }),
    onSuccess: data => {
      queryClient.setQueryData(moduleKeys.modules(subdomain, data.id || ''), data);
      queryClient.invalidateQueries({ queryKey: questKeys.board(subdomain) });
    },
  });
};
