import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import type { ListPromotionBookingInput, ListPromotionBookingOutput } from '@zealy/schemas';
import { getSubdomain } from '@zealy/utils';

import { listPromotionBooking } from '../subscription.service';
import { subscriptionsKeys } from '../subscriptions.keys';

export const usePromotionBookings = ({
  subdomain = getSubdomain(),
  query,
  ...options
}: Partial<UseQueryOptions<ListPromotionBookingOutput>> & {
  subdomain?: string;
  query?: ListPromotionBookingInput;
} = {}) => {
  return useQuery({
    ...options,
    queryKey: subscriptionsKeys.listPromotionBooking(subdomain),
    queryFn: () => listPromotionBooking({ params: { subdomain }, query }),
  });
};
