import type { SetRoleInput } from '@zealy/schemas';

import { apiV2 } from '../services';

export const impersonateUser = async (email: string) => {
  const { status, body } = await apiV2.auth.impersonate({
    body: {
      email,
    },
  });

  if (status !== 200) {
    throw new Error('Failed to impersonate');
  }

  return body;
};

export const setRole = async (
  params: {
    userId: string;
    subdomain: string;
  },
  role: SetRoleInput['role'],
) => {
  const { status, body } = await apiV2.communityMember.setRole({
    params,
    body: {
      role,
    },
  });

  if (status !== 200) {
    throw new Error('Failed to impersonate');
  }

  return body;
};
