import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import type { GetCRMInput, GetCRMOutput } from '@zealy/utils';
import { getSubdomain } from '@zealy/utils';

import { crmKeys } from '../crm.keys';
import { getCRM } from '../crm.service';

export const useCRM = ({
  subdomain = getSubdomain(),
  filters,
  ...options
}: Partial<UseQueryOptions<GetCRMOutput>> & {
  subdomain?: string;
  filters: GetCRMInput['query'];
}) => {
  return useQuery({
    ...options,
    queryKey: crmKeys.crm(subdomain, filters),
    queryFn: () => getCRM({ path: { subdomain }, query: filters }),
  });
};
