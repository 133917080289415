import type { ClientInferRequest, contract } from '@zealy/contracts';

import { apiV2 } from '../services';

export type ListNotificationsOutput = Awaited<ReturnType<typeof getNotifications>>;

export const getNotifications = async (
  options: ClientInferRequest<typeof contract.notification.list>,
) => {
  const res = await apiV2.notification.list({
    ...options,
  });

  if (res.status === 200) {
    return res.body;
  } else {
    // handle error here
    throw new Error('getNotifications failed');
  }
};

export const patchNotifications = async (
  options: ClientInferRequest<typeof contract.notification.setReadStatus>,
) => {
  const res = await apiV2.notification.setReadStatus({
    ...options,
  });

  if (res.status !== 200) {
    // handle error here
    throw new Error('getNotifications failed');
  }
};
