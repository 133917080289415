import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import type { GetUserByFieldOutput } from '@zealy/schemas';

import { usersKeys } from '../users.keys';
import { getUserByField } from '../users.service';

export const useUserByField = (
  query: {
    field: 'name';
    value: string;
  },
  options?: UseQueryOptions<GetUserByFieldOutput>,
) => {
  return useQuery({
    ...options,
    queryKey: usersKeys.byField(query.field, query.value),
    queryFn: () => getUserByField(query),
    enabled: !!query.value && !!query.field,
    retry: false,
  });
};
