import type { FetchOptions } from 'ofetch';

import type { GetInvitationInput } from '@zealy/schemas';
import type { GetReferralLink } from '@zealy/utils';

import type { ResponseType } from '../services/utils';
import type {
  Community,
  CommunityInviteOptions,
  CreateCommunity,
  ListCommunities,
} from './communities.types';
import { api, apiV2 } from '../services';

export const getCommunities = async (
  category = 'all',
  limit = 12,
  page?: number,
  web2?: boolean,
): Promise<ListCommunities> => {
  if (!web2) {
    return api.get<ListCommunities>('/communities', {
      params: {
        category,
        limit,
        page,
      },
    });
  } else {
    const res = await apiV2.community.list({
      query: {
        category: category as 'all' | 'upcoming' | 'featured',
      },
    });

    if (res.status === 200) {
      const communities = res.body.communities.map(
        ({ totalTwitterFollowers, totalQuests, ...rest }) => ({
          ...rest,
          twitterFollowers: totalTwitterFollowers,
          quests: `${totalQuests}`,
        }),
      ) as never as Community[];

      return {
        communities,
        totalCommunities: communities.length,
        totalPages: 1,
      };
    } else {
      // handle error here
      throw new Error('getCommunities fail');
    }
  }
};

export const getCommunity = async (
  subdomain: string,
  options?: Omit<FetchOptions<ResponseType>, 'method'> | undefined,
) => {
  if (!subdomain || subdomain === 'root' || subdomain === '_') throw new Error('Invalid subdomain');
  return api.get<Community>(`/communities/${subdomain}`, options);
};

export const createCommunity = async (
  data: CreateCommunity,
  utmParams?: Record<string, string>,
) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (value) {
      formData.append(key, value);
    }
  });

  const response = await api.post<Community>('/communities', {
    body: formData,
    params: utmParams,
  });
  return response;
};

export type UpdatableCommunityProperties =
  | 'type'
  | 'sector'
  | 'website'
  | 'launchDate'
  | 'name'
  | 'billingEmail'
  | 'consumeInvitesEnabled'
  | 'minimumRequiredXp'
  | 'visibility'
  | 'requiredFields';

export type UpdateCommunityPayload = Partial<Pick<Community, UpdatableCommunityProperties>>;

export const updateCommunity = async (subdomain: string, data: UpdateCommunityPayload) => {
  const includesFile = Object.keys(data).some(key => key === 'image');

  if (includesFile) {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (value) {
        formData.append(key, value as string);
      }
    });

    return api.patch<Community>(`/communities/${subdomain}`, {
      body: formData,
    });
  }
  return api.patch<Community>(`/communities/${subdomain}`, {
    body: JSON.stringify(data),
  });
};

export const deleteCommunity = async (subdomain: string) =>
  api.delete<Community>(`/communities/${subdomain}`);

export const resetLeaderboard = async (subdomain: string) =>
  api.delete<Community>(`/communities/${subdomain}/leaderboard`);

export const getTwitterOAuthURI = async (subdomain: string) =>
  api.get<{ url: string }>(`/communities/${subdomain}/oauth/twitter`);

export const getReferralLink = async (subdomain: string) =>
  api.get<GetReferralLink>(`/communities/${subdomain}/users/me/referral-link`);

// TODO:
// Import from types package. when types are generated.
/**
 * External community account
 */
export interface CommunityExternalAccount {
  id: string;
  communityId: string;
  accountName: string;
  accountId: string;
  deleted: boolean;
  createdAt: Date;
  updatedAt: Date;
  type: 'twitter' | 'discord';
}

export const verifyCommunityExternalAccounts = async (subdomain: string) => {
  const response = await api.get<CommunityExternalAccount[]>(
    `/communities/${subdomain}/oauth/verify`,
  );
  return response;
};

export const unlinkCommunityAccount = async (
  subdomain: string,
  accountType: 'twitter' | 'discord',
) => {
  const response = await api.delete(`/communities/${subdomain}/oauth/${accountType}`);
  return response;
};

export const getCommunityInvite = async (subdomain: string, options: CommunityInviteOptions) => {
  const response = await api.get<{ id: string }>(
    `/communities/${subdomain}/members/invitation-link`,
    {
      query: options,
    },
  );

  return response;
};

export interface EmailInvite {
  email: string;
  role: string;
}

export const sendCommunityInvite = async (subdomain: string, invites: EmailInvite[]) =>
  api.post(`/communities/${subdomain}/members/email-invites`, {
    body: JSON.stringify({ invites }),
  });

export const getUserCommunities = async (
  { pageParam = 0, limit = 30, search = '' },
  config: FetchOptions<'json'> = {},
) => {
  const { communities, total } = await api<{
    communities: Community[];
    total: number;
  }>('/users/me/communities', {
    ...config,
    params: {
      ...config?.params,
      page: pageParam,
      limit,
      search,
    },
  });

  return {
    results: communities,
    nextPage: pageParam + 1,
    hasNextPage: pageParam + 1 < Math.ceil(total / limit),
  };
};

export const getExploreCommunities = async (
  {
    category = 'all',
    pageParam = 0,
    search,
    limit = 30,
    endpoint = '/communities',
    web2,
  }: {
    category?: string;
    search?: string;
    pageParam?: number;
    limit?: number;
    endpoint?: string;
    web2?: boolean;
  },
  config: FetchOptions<'json'> = {},
): Promise<{
  results: Community[];
  nextPage: number;
  hasNextPage: boolean;
  totalCommunities: number;
}> => {
  let communities: Community[];
  let totalCommunities: number;
  let hasNextPage: boolean;

  if (!web2) {
    ({ communities, totalCommunities } = await api.get<{
      communities: Community[];
      totalCommunities: number;
    }>(endpoint, {
      ...config,
      params: {
        ...config?.params,
        category,
        search,
        page: pageParam,
        limit,
      },
    }));
    hasNextPage = pageParam + 1 < Math.ceil(totalCommunities / limit);

    return {
      results: communities,
      nextPage: pageParam + 1,
      hasNextPage,
      totalCommunities,
    };
  } else {
    const res = await apiV2.community.list({
      query: {
        category: category as 'all' | 'upcoming' | 'featured',
      },
    });

    if (res.status === 200) {
      return {
        results: res.body.communities.map(({ totalTwitterFollowers, totalQuests, ...rest }) => ({
          ...rest,
          twitterFollowers: totalTwitterFollowers,
          quests: `${totalQuests}`,
        })) as never as Community[],
        hasNextPage: false,
        totalCommunities: res.body.communities.length,
        nextPage: pageParam + 1,
      };
    }
    // handle error here
    throw new Error('getExploreCommunities fail');
  }
};

export const startFromTemplate = async (domain: string) =>
  api.post(`/communities/${domain}/start-from-templates`);

export const leaveCommunity = async (subdomain: string, userId: string) =>
  api.delete(`/communities/${subdomain}/members/${userId}`);

export const acceptInvitation = async (invitationId: string) =>
  api.post('/users/me/accept-invitation', {
    body: { invitationId },
  });

export const joinCommunity = async (subdomain: string, invitationId?: string) =>
  invitationId ? acceptInvitation(invitationId) : api.post(`/communities/${subdomain}/members`);

export const getInvitation = async (params: GetInvitationInput) => {
  const response = await apiV2.invitation.get({ params });

  if (response.status === 400 || response.status === 404) {
    throw new Error(response.body.message);
  }
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  }

  return response.body;
};

export const setUserCommunities = async ({ communityIds }: { communityIds: string[] }) => {
  if (!communityIds.length) {
    return;
  }
  return api.patch('/users/me/communities', {
    body: JSON.stringify({ communityIds }),
  });
};

/**
 * Reports a given community by subdomain and reason
 */
export function reportCommunity(subdomain: string, reason: string) {
  return api.post(`/communities/${subdomain}/report`, {
    body: {
      reason,
    },
  });
}

export interface DiscordRole {
  id: string;
  name: string;
  position: number;
  editable?: boolean;
}

export const getCommunityDiscordRoles = async (subdomain: string) =>
  api.get<DiscordRole[]>(`/communities/${subdomain}/plugins/discord/roles`);

interface EstimatedAmountToFundWallet {
  data: {
    network: string;
    estimationAmount: {
      low: number;
      average: number;
      fast: number;
      fastest: number;
    };
  };
}
export interface EstimatedAmountToFundWalletQuery {
  maxSupply: number;
  network: string;
  subdomain: string;
}

export const getEstimatedNFTCost = async ({
  subdomain,
  maxSupply,
  network,
}: EstimatedAmountToFundWalletQuery): Promise<string> => {
  const { data } = await api.get<EstimatedAmountToFundWallet>(
    `/communities/${subdomain}/nft-rewards/estimated-amount-to-fund`,
    {
      params: {
        maxSupply,
        network,
      },
    },
  );

  return Number(data.estimationAmount.fastest).toFixed(5);
};

export interface CommunityWallet {
  data: {
    id: string;
    communityId: string;
    address: string;
    name: string;
    createdAt: string;
    updatedAt: string;
    deletedAt?: string | null;
  };
}

export const getCommunityWallet = async (subdomain: string) => {
  const { data } = await api.get<CommunityWallet>(`/communities/${subdomain}/nft-rewards/wallet`);
  return data.address;
};

export const uploadImage = async (file: File, subdomain: string) => {
  const formData = new FormData();
  formData.append('file', file);
  const { url } = await api.post<{ url: string }>('/files', {
    query: {
      subdomain,
    },
    body: formData,
  });

  return url;
};

export interface FailedDiscordRolesDelivery {
  data: {
    missingPermissionCount: number;
    memberNotFoundCount: number;
  };
}

export const getFailedDiscordRolesDelivery = async (subdomain: string) => {
  const { data } = await api.get<FailedDiscordRolesDelivery>(
    `/communities/${subdomain}/failed-discord-integration`,
  );
  return data;
};

export type CommunitySteps = Community & {
  connectedDiscord: boolean;
  hasSprint: boolean;
  hasCollaborators: boolean;
  has3Quests: boolean;
  hasModules: boolean;
};

export const getCommunityOnboardingSteps = async (subdomain: string) =>
  api.get<CommunitySteps>(`/communities/${subdomain}/steps`);

export type ClaimNFTResponse = {
  outcome: 'success' | 'failure' | 'pending';
};

export const claimNFT = async (
  subdomain: string,
  address: string,
  questId: string,
  nftId: string | number,
) => {
  const data = await api.post<ClaimNFTResponse>(
    `/communities/${subdomain}/quests/${questId}/nft-rewards/${nftId}/claim`,
    {
      body: JSON.stringify({ id: nftId, address, questId }),
    },
  );
  return data;
};

export const listCommunitySecrets = async (subdomain: string) => {
  const res = await apiV2.communitySecret.listCommunitySecret({ params: { subdomain } });

  if (res.status !== 200) {
    throw new Error('listCommunitySecrets error');
  }

  return res.body;
};

export const createCommunitySecret = async (subdomain: string) => {
  const res = await apiV2.communitySecret.createCommunitySecret({ params: { subdomain } });

  if (res.status !== 201) {
    throw new Error('generateCommonutiySecret error');
  }

  return res.body;
};
