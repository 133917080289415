import { useQuery } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import type { RQOptions } from '../../types';
import { analyticsKeys } from '../analytics.keys';
import { getClaimedQuestsStatusCount } from '../analytics.service';
import { FiveMinutes, InitialRetryDelay } from '../constants';

type UseClaimedQuestsStatusCountArgs = {
  subdomain?: string;
  startDate: Date;
  endDate: Date;
};

export const useClaimedQuestsStatusCount = ({
  subdomain = getSubdomain(),
  startDate,
  endDate,
  ...options
}: UseClaimedQuestsStatusCountArgs & RQOptions<typeof getClaimedQuestsStatusCount>) => {
  const q = useQuery({
    refetchInterval: query => (!query.state.data?.isComputed ? InitialRetryDelay : false),
    ...options,
    queryKey: analyticsKeys.claimedQuestsStatusCount(subdomain, startDate, endDate),
    queryFn: () => getClaimedQuestsStatusCount(subdomain, startDate, endDate),
    staleTime: FiveMinutes,
  });

  return { ...q, isPending: q.isPending || !q.data?.isComputed };
};
