import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { PatchReorderQuestsInput } from '@zealy/utils';
import { getSubdomain } from '@zealy/utils';

import { questKeys } from '../quests.keys';
import { reorderQuests } from '../quests.service';

/**
 * Hook for updating a quest
 * @example ```
 * const { mutate, isLoading } = useReorderQuests();
 * ```
 */
export const useReorderQuests = (subdomain = getSubdomain()) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: PatchReorderQuestsInput) => reorderQuests({ subdomain, payload: data }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: questKeys.board(subdomain) });
    },
  });
};
