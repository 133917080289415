import { useQuery } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import { getEstimatedNFTCost } from '../communities.service';

/**
 * Gets an invite link with the configured options
 * @example ```
 * const { data, isLoading } = useNFTCost({
 *    totalSupply: 100,
 *    subdomain: 'my-community',
 *    network: 'polygon',
 *  }, 'my-community');
 * ```
 */
export const useNFTCost = ({
  subdomain = getSubdomain(),
  network,
  maxSupply,
}: {
  subdomain?: string;
  network:
    | 'ethereum-mainnet'
    | 'ethereum-ropsten'
    | 'ethereum-goerli'
    | 'binance-mainnet'
    | 'binance-testnet'
    | 'polygon-mainnet'
    | 'polygon-mumbai'
    | 'avalanche-mainnet'
    | 'avalanche-fuji';
  maxSupply: number;
}) => {
  return useQuery<string, Error>({
    queryKey: ['fund-estimate', network, maxSupply],
    queryFn: () => getEstimatedNFTCost({ subdomain, network, maxSupply }),
    enabled: !!maxSupply,
  });
};
