import { useQuery } from '@tanstack/react-query';

import { getSubdomain, isValidSubdomain } from '@zealy/utils';

import { isUserConnected } from '../../users';
import { communityKeys } from '../communities.keys';
import { getReferralLink } from '../communities.service';

export const useReferralLink = (subdomain = getSubdomain()) => {
  return useQuery({
    queryKey: communityKeys.referralLink(subdomain),
    queryFn: () => getReferralLink(subdomain),
    enabled: isValidSubdomain(subdomain) && isUserConnected(),
  });
};
