import type {
  ApiFetcherArgs,
  ClientInferRequest,
  ClientInferResponseBody,
  contract,
} from '@zealy/contracts';
import type { CreateLeaderboardSprintInput, UpdateLeaderboardSprintInput } from '@zealy/schemas';
import type { PatchSprintsInput, PostSprintsInput } from '@zealy/utils';

import { apiV2 } from '../services';
import { api } from '../services/api';

export const getLeaderboard = async (
  subdomain: string,
  sprintId?: string,
  page: number = 1,
  _next?: ApiFetcherArgs['next'],
) => {
  const res = await apiV2.leaderboard.get({
    params: { subdomain },
    query: {
      page,
      sprintId,
    },
    _next,
  });

  if (res.status === 200) {
    return res.body;
  } else {
    // handle error here
    throw new Error('getLeaderboard error');
  }
};

export const getSprints = async (
  subdomain: string,
  options?: Partial<
    Omit<ClientInferRequest<typeof contract.leaderboard.listCommunitySprints>, 'params'>
  > & {
    _next?: ApiFetcherArgs['next'];
  },
) => {
  const res = await apiV2.leaderboard.listCommunitySprints({
    params: { subdomain },
    ...options,
  });

  if (res.status === 200) {
    return res.body.data;
  } else {
    // handle error here
    throw new Error('getSprints error');
  }
};

export type GetSprintsOutput = Awaited<ReturnType<typeof getSprints>>;

export const createSprint = async (subdomain: string, data: PostSprintsInput) => {
  const res = await apiV2.leaderboardSprint.create({
    params: { subdomain },
    body: data as CreateLeaderboardSprintInput,
  });

  if (res.status === 200) {
    return res.body;
  } else {
    // handle error here
    throw new Error('createSprint error');
  }
};

export const updateSprint = async (
  subdomain: string,
  sprintId: string,
  data: PatchSprintsInput,
) => {
  const res = await apiV2.leaderboardSprint.update({
    params: { subdomain },
    body: { ...data, id: sprintId } as UpdateLeaderboardSprintInput,
  });

  if (res.status === 200) {
    return res.body;
  } else {
    // handle error here
    throw new Error('updateSprint error');
  }
};

export const exportLeaderboard = (
  subdomain: string,
  data: {
    email: string;
    sprintId?: string;
    limit?: number;
  },
) =>
  api.post(`/communities/${subdomain}/exports`, {
    body: data,
  });

export const deleteSprint = async (subdomain: string, sprintId: string) => {
  const res = await apiV2.leaderboardSprint.delete({
    params: { subdomain },
    query: {
      id: sprintId,
    },
  });

  if (res.status !== 200) {
    throw new Error('deleteSprint error');
  }
};

export const getRank = async (subdomain: string, userId: string, sprintId: string) => {
  const res = await apiV2.leaderboard.getRank({
    params: { subdomain, userId, sprintId },
  });

  if (res.status === 200) {
    return res.body;
  } else {
    // handle error here
    throw new Error('getRank error');
  }
};

export const getUpcomingAndRecentSprints = async () => {
  const res = await apiV2.leaderboardSprint.list();

  if (res.status === 200) {
    return res.body.data;
  } else {
    throw new Error('getUpcomingAndRecentSprints error');
  }
};
