import { useQuery } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import type { RQOptions } from '../../types';
import type { Interval } from '../types';
import { analyticsKeys } from '../analytics.keys';
import { getNewMembers } from '../analytics.service';
import { FiveMinutes, InitialRetryDelay } from '../constants';

type UseNewMembersArgs = {
  subdomain?: string;
  startDate: Date;
  endDate: Date;
  interval: Interval;
};

export const useNewMembers = ({
  subdomain = getSubdomain(),
  startDate,
  endDate,
  interval,
  ...options
}: UseNewMembersArgs & RQOptions<typeof getNewMembers>) => {
  const q = useQuery({
    refetchInterval: query => (!query.state.data?.isComputed ? InitialRetryDelay : false),
    staleTime: FiveMinutes,
    ...options,
    queryKey: analyticsKeys.newMembers(subdomain, startDate, endDate, interval),
    queryFn: () => getNewMembers(subdomain, startDate, endDate, interval),
  });

  return { ...q, isPending: q.isPending || !q.data?.isComputed };
};
