import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import { getSubdomain, isValidSubdomain } from '@zealy/utils';

import { isUserConnected } from '../../users';
import { webhooksKeys } from '../webhooks.keys';
import { listWebhookEvents } from '../webhooks.service';

export const useListWebhookEvents = (
  subdomain = getSubdomain(),
  webhookId: string,
  status: string[],
  initialData?: Awaited<ReturnType<typeof listWebhookEvents>>,
) => {
  return useInfiniteQuery({
    queryKey: webhooksKeys.events(subdomain, webhookId, status.join(',')),
    queryFn: ({ pageParam }) =>
      listWebhookEvents({
        params: {
          subdomain,
          webhookId,
        },
        query: {
          page: pageParam,
          statusFilter: status.join(',') as any,
        },
      }),

    getNextPageParam: lastPage => lastPage.page + 1,
    placeholderData: keepPreviousData,
    enabled: isUserConnected() && isValidSubdomain(subdomain),
    initialData: initialData
      ? {
          pages: [initialData],
          pageParams: [0],
        }
      : undefined,
    initialPageParam: 0,
  });
};
