export const webhooksKeys = {
  types: (id?: string) => ['api-keys', id],
  list: (subdomain: string) => ['webhook', subdomain],
  item: (subdomain: string, webhookId: string) => ['webhook', subdomain, webhookId],
  events: (subdomain: string, webhookId: string, filters: string) => [
    'webhook:events',
    subdomain,
    webhookId,
    filters,
  ],
};
