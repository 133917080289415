import { useQuery } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import type { RQOptions } from '../../types';
import { analyticsKeys } from '../analytics.keys';
import { getTopReferrals } from '../analytics.service';
import { FiveMinutes, InitialRetryDelay } from '../constants';

export type UseTopReferralsArgs = {
  subdomain?: string;
  startDate: Date;
  endDate: Date;
};

export const useTopReferrals = ({
  subdomain = getSubdomain(),
  startDate,
  endDate,
  ...options
}: UseTopReferralsArgs & RQOptions<typeof getTopReferrals>) => {
  const q = useQuery({
    refetchInterval: query => (!query.state.data?.isComputed ? InitialRetryDelay : false),
    staleTime: FiveMinutes,
    ...options,
    queryKey: analyticsKeys.topReferrals(subdomain, startDate, endDate),
    queryFn: () => getTopReferrals(subdomain, startDate, endDate),
  });

  return { ...q, isPending: q.isPending || !q.data?.isComputed };
};
