export const reviewsKeys = {
  list: (subdomain: string, options?: any) =>
    options ? ['reviews', subdomain, options] : ['reviews', subdomain],
  claim: (subdomain: string, id: string) => ['reviews', subdomain, id],
  events: (subdomain: string, id: string) => ['reviews', subdomain, id, 'events'],
  alerts: (subdomain: string, id: string) => ['reviews', subdomain, id, 'alerts'],
  userStats: (subdomain: string, id: string) => ['reviews', subdomain, id, 'userStats'],
  comments: (subdomain: string, questId: string) => ['reviews', subdomain, 'comments', questId],
  pendingCount: (subdomain: string) => ['reviews', subdomain, 'pendingCount'],
};
