import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import { questKeys } from '../quests.keys';
import { deleteQuest } from '../quests.service';

/**
 * Hook for deleting a quest
 * @example ```
 * const { mutate, isLoading } = useDeleteQuest();
 * ```
 */
export const useDeleteQuest = (subdomain = getSubdomain()) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (questId: string) => deleteQuest({ subdomain, questId }),
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: questKeys.board(subdomain) });
    },
  });
};
