import { useQuery } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import type { Community, CommunityInviteOptions } from '../communities.types';
import { communityKeys } from '../communities.keys';
import { getCommunityInvite } from '../communities.service';

/**
 * Gets an invite link with the configured options
 * @example ```
 * const { data, isLoading } = useCommunityInvite({
 *    role: 'editor',
 *    expire: '2019-01-25T02:00:00.000Z',
 *    maxuse: 5
 *  }, 'my-community');
 *
 *    copy(`location.origin/c/${subdomain}/invite/${data?.id}`)
 * ```
 */
export const useCommunityInvite = (
  options: CommunityInviteOptions,
  subdomain?: string,
  initialData?: Community,
) => {
  const domain = subdomain || getSubdomain();

  return useQuery({
    queryKey: communityKeys.invite(domain, options),
    queryFn: () => getCommunityInvite(domain, options),
    initialData,
    enabled: !!domain,
    retry: 3,
  });
};
