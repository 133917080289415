import type { FetchOptions } from 'ofetch';

import type { ApiFetcherArgs, ClientInferRequest, contract } from '@zealy/contracts';
import type { CreateReviewInput, PatchReviewsInput, PatchReviewsOutput } from '@zealy/schemas';

import { api, apiV2 } from '../services';

export const resetClaims = async (subdomain: string, questId?: string) => {
  const res = await apiV2.review.resetClaims({ params: { subdomain }, body: { questId } });

  if (res.status == 200) {
    return res.body;
  } else {
    // handle error here
    throw new Error('resetClaims failed');
  }
};

export const listReviews = async (options: ClientInferRequest<typeof contract.review.list>) => {
  const res = await apiV2.review.list({
    ...options,
    query: {
      ...options.query,
      status: options.query?.status ? JSON.stringify(options.query?.status) : undefined,
      userId: options.query?.userId ? JSON.stringify(options.query?.userId) : undefined,
      reviewerId: options.query?.reviewerId ? JSON.stringify(options.query?.reviewerId) : undefined,
      questId: options.query?.questId ? JSON.stringify(options.query?.questId) : undefined,
    },
  });

  if (res.status === 200) {
    return res.body;
  } else {
    // handle error here
    throw new Error('listReviews failed');
  }
};

export const getReview = async (
  options: ClientInferRequest<typeof contract.review.get> & { _next?: ApiFetcherArgs['next'] },
) => {
  const res = await apiV2.review.get(options);

  if (res.status === 200) {
    return res.body;
  } else {
    // handle error here
    throw new Error('getReview failed');
  }
};

export const getUserStats = async (
  options: ClientInferRequest<typeof contract.review.getUserStats>,
) => {
  const res = await apiV2.review.getUserStats(options);

  if (res.status === 200) {
    return res.body;
  } else {
    // handle error here
    throw new Error('getUserStats failed');
  }
};

export const getReviewEvents = async (
  options: ClientInferRequest<typeof contract.review.listEvents>,
) => {
  const res = await apiV2.review.listEvents(options);

  if (res.status === 200) {
    return res.body;
  } else {
    // handle error here
    throw new Error('getReviewEvents failed');
  }
};

export const updateReviews = async (
  subdomain: string,
  body: CreateReviewInput,
  featureFlag?: boolean,
) => {
  if (featureFlag) {
    const res = await apiV2.review.create({ params: { subdomain }, body });

    if (res.status == 200) {
      return res.body;
    } else {
      // handle error here
      throw new Error('resetClaims failed');
    }
  } else {
    return api.post<PatchReviewsOutput>(`/communities/${subdomain}/claimed-quests/review/v2`, {
      body,
    });
  }
};

export const cancelReview = async (
  subdomain: string,
  claimedQuestIds: string[],
  featureFlag?: boolean,
) => {
  if (featureFlag) {
    return updateReviews(
      subdomain,
      { claimedQuestIds, status: 'pending', bonusXP: 0 },
      featureFlag,
    );
  } else {
    return api.post(`/communities/${subdomain}/claimed-quests/cancel`, {
      body: { claimedQuestIds },
    });
  }
};

export const exportReviews = (
  {
    subdomain,
    body,
  }: {
    subdomain: string;
    body: {
      status: string[];
      questId: string[];
      reviewerId: string[];
      userId: string[];
      recurrence: string[];
    };
  },
  options?: Omit<FetchOptions<'json'>, 'query' | 'body'>,
) =>
  api.post(`/communities/${subdomain}/claimed-quests/export`, {
    ...options,
    body,
  });

export const getRecentReviewComments = async (subdomain: string, questId: string) => {
  const comments = await api.get<string[]>(
    `/communities/${subdomain}/claimed-quests/${questId}/comments`,
  );

  const uniqueComments = Array.from(new Set(comments));
  return uniqueComments;
};

export const getReviewAlerts = async (
  options: ClientInferRequest<typeof contract.review.getReviewAlerts>,
) => {
  const res = await apiV2.review.getReviewAlerts(options);

  if (res.status === 200) {
    return res.body;
  } else {
    // handle error here
    throw new Error('getReviewAlerts failed');
  }
};

export async function getPendingReviews(subdomain: string) {
  const result = await api.get<{ count: number }>(
    `/communities/${subdomain}/claimed-quests/pending-count`,
  );
  return result.count;
}
