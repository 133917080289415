import { useQuery } from '@tanstack/react-query';

import type { GetApiKeysOutput } from '@zealy/utils';

import { getMe } from '../../services/utils';
import { isUserConnected } from '../../users';
import { apiKeyKeys } from '../apiKeys.keys';
import { getApiKeys } from '../apiKeys.service';

export const useApiKeys = (userId = getMe(), initialData?: GetApiKeysOutput) => {
  return useQuery({
    queryKey: apiKeyKeys.keys(userId),
    queryFn: async () => getApiKeys(userId ?? ''),
    initialData,
    enabled: isUserConnected(),
  });
};
