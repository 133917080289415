import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import type { Invite } from '../../users/users.service';
import { getInvites, isUserConnected } from '../../users/users.service';
import { usersKeys } from '../users.keys';

export interface InviteResult {
  results: Invite[];
  nextPage: number;
  hasNextPage: boolean;
}

/**
 * Gets an authenticated user's invites
 * @example ```
 * const { data, isLoading } = useInvites();
 * console.log(data)
 * ```
 */
export const useInvites = (
  {
    subdomain = getSubdomain(),
    userId,
    invitesQuestId,
    status = 'active',
  }: { subdomain?: string; userId?: string; invitesQuestId?: string; status?: Invite['status'] },
  initialData?: InviteResult,
) => {
  return useInfiniteQuery({
    queryKey: usersKeys.invites(userId ?? '', subdomain, status),
    queryFn: ({ pageParam }) =>
      getInvites({ pageParam, invitesQuestId, userId: userId ?? '', subdomain, status }),
    getNextPageParam: lastPage => {
      if (lastPage.hasNextPage) {
        return lastPage.nextPage;
      }
      return undefined;
    },
    placeholderData: keepPreviousData,
    initialData: initialData
      ? {
          pages: [initialData],
          pageParams: [1],
        }
      : undefined,
    enabled: isUserConnected() && !!userId,
    retry: 3,
    initialPageParam: 1,
  });
};
