import type { QueryClientConfig, QueryClientProviderProps } from '@tanstack/react-query';
import type { PropsWithChildren } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';

import type { ConfigOptions } from './services/config';
import { updateConfigs } from './services/config';

/**
 * Creates a new Query Client Instrace for React Query.
 * @param options
 * @param queryClientConfig
 */
export function createZealyQueryClient(
  options: ConfigOptions,
  queryClientConfig?: QueryClientConfig,
) {
  // Sets up the apis base URL
  updateConfigs(options);

  /**
   * Query client instance
   */
  const queryClient = new QueryClient(queryClientConfig);

  /**
   * Query Client Provider component
   */
  const ZealyQueryProvider = ({ children }: PropsWithChildren<QueryClientProviderProps>) => {
    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
  };

  return {
    ZealyQueryProvider,
    queryClient,
  };
}
