import { useQuery } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import { getCommunitySubscription } from '../subscription.service';
import { subscriptionsKeys } from '../subscriptions.keys';

export const useSubscription = (options?: { enabled?: boolean }) => {
  const subdomain = getSubdomain();
  return useQuery({
    queryKey: subscriptionsKeys.subscription(subdomain),
    queryFn: () => getCommunitySubscription(subdomain),
    ...(options ?? {}),
  });
};
