import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import type { UpdateCommunityPayload } from '../communities.service';
import { communityKeys } from '../communities.keys';
import { updateCommunity } from '../communities.service';

/**
 * Hook for updating a community
 * @example ```
 * const { mutate, isLoading } = useUpdateCommunity();
 * mutate({
 *    name: 'My Community',
 *    id: '0b9e7c9e-9b7b-4a5a-8b7a-5b9e7c9e9b7b',
 *    subdomain: 'my-community'
 * });
 * ```
 */
export const useUpdateCommunity = (subdomain?: string) => {
  const domain = subdomain || getSubdomain();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: UpdateCommunityPayload) => updateCommunity(domain, data),
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: communityKeys.community(data.subdomain) });
      queryClient.invalidateQueries({ queryKey: communityKeys.onboarding(data.subdomain) });
    },
  });
};
