import type { FetchOptions } from 'ofetch';

import { api } from '../services/api';

export interface NPS {
  score: number;
  month: number;
  year: number;
}

export const getLastNPSScore = (userId: string, options?: FetchOptions) =>
  api.get<NPS>(`/users/${userId}/nps`, options);

export const postNPS = (
  userId: string,
  body: {
    score: number;
    suggestion: string;
    subdomain: string;
  },
) =>
  api.post<NPS>(`/users/${userId}/nps`, {
    body,
  });
