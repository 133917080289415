import type { FetchOptions } from 'ofetch';

import type {
  DeleteApiKeyOutput,
  GetApiKeysOutput,
  PostApiKeyInput,
  PostApiKeyOutput,
} from '@zealy/utils';
import { publicApiRoutes } from '@zealy/utils';

import { api } from '../services/api';

export const getApiKeys = (userId: string, options?: FetchOptions) =>
  api.get<GetApiKeysOutput>(`/users/${userId}/api-keys`, options);

const getPayload = (writeAccess: boolean, name?: string): PostApiKeyInput => ({
  name,
  authorizedRoutes: writeAccess
    ? publicApiRoutes
    : publicApiRoutes.filter(({ method }) => method === 'GET'),
});

export const postApiKey = (userId: string, writeAccess: boolean, name?: string) =>
  api.post<PostApiKeyOutput>(`/users/${userId}/api-keys-v2`, {
    body: JSON.stringify(getPayload(writeAccess, name)),
  });

export const deleteApiKey = (userId: string, apiKeyId: string) =>
  api.delete<DeleteApiKeyOutput>(`/users/${userId}/api-keys-v2/${apiKeyId}`);
