import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { PatchQuestInput, PatchQuestOutput } from '@zealy/utils';
import { getSubdomain } from '@zealy/utils';

import { questKeys } from '../quests.keys';
import { updateQuest } from '../quests.service';

/**
 * Hook for updating a quest
 * @example ```
 * const { mutate, isLoading } = useUpdateQuest();
 * ```
 */
export const useUpdateQuest = (questId: string, subdomain = getSubdomain()) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: PatchQuestInput) => updateQuest({ subdomain, questId, data }),
    onSuccess: (data: PatchQuestOutput) => {
      queryClient.invalidateQueries({ queryKey: questKeys.board(subdomain) });
    },
  });
};
