import { useMutation, useQueryClient } from '@tanstack/react-query';

import { usersKeys } from '../../users';
import { otpLogin } from '../auth.service';

export const useVerifyOtp = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: otpLogin,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: usersKeys.user('me') });
    },
  });
};
