import { useQuery } from '@tanstack/react-query';

import type { ListReviewEventsOutput } from '@zealy/schemas';
import { getSubdomain } from '@zealy/utils';

import { reviewsKeys } from '../reviews.keys';
import { getReviewEvents } from '../reviews.service';

export const useReviewEvents = (
  id: string,
  subdomain = getSubdomain(),
  initialData?: ListReviewEventsOutput,
) => {
  return useQuery({
    queryKey: reviewsKeys.events(subdomain, id),
    queryFn: async () =>
      getReviewEvents({
        params: { subdomain, id },
      }),
    initialData,
    enabled: !!id,
  });
};
