import { useQuery } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import type { RQOptions } from '../../types';
import type { Interval } from '../types';
import { analyticsKeys } from '../analytics.keys';
import { getDiscordMembers } from '../analytics.service';
import { FiveMinutes, InitialRetryDelay } from '../constants';

type UseDiscordMembersArgs = {
  subdomain?: string;
  startDate: Date;
  endDate: Date;
  interval: Interval;
};

export const useDiscordMembers = ({
  subdomain = getSubdomain(),
  startDate,
  endDate,
  interval,
  ...options
}: UseDiscordMembersArgs & RQOptions<typeof getDiscordMembers>) => {
  const q = useQuery({
    refetchInterval: query => (!query.state.data?.isComputed ? InitialRetryDelay : false),
    ...options,
    queryKey: analyticsKeys.discordMembers(subdomain, startDate, endDate, interval),
    queryFn: () => getDiscordMembers(subdomain, startDate, endDate, interval),
    staleTime: FiveMinutes,
  });

  return { ...q, isPending: q.isPending || !q.data?.isComputed };
};
