import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import { deleteCommunity } from '../communities.service';

export const useDeleteCommunity = (subdomain = getSubdomain()) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => deleteCommunity(subdomain),
    onSuccess: () => {
      queryClient.removeQueries();
    },
  });
};
