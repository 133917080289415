import { useQuery } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import { getPricingTable } from '../subscription.service';
import { subscriptionsKeys } from '../subscriptions.keys';

export const usePricingTable = (options?: { enabled?: boolean }) => {
  const subdomain = getSubdomain();
  return useQuery({
    queryKey: subscriptionsKeys.pricingTable(subdomain),
    queryFn: () => getPricingTable(subdomain),
    ...(options ?? {}),
  });
};
