import type { PartnershipsRequestQuery, PartnershipStatus } from '@zealy/schemas';

import { apiV2 } from '../services';

export const listPartnerships = async (subdomain: string, query: PartnershipsRequestQuery) => {
  const response = await apiV2.partnership.listPartnershipRequests({
    params: { subdomain },
    query,
  });

  if (response.status !== 200) {
    throw new Error('Cannot get new members.');
  }

  return response.body;
};

export const updatePartnershipSettings = async (
  subdomain: string,
  partnershipsEnabled: boolean,
  partnershipsRequiredMemberCount: number,
) => {
  return apiV2.community.updateSettings({
    params: { subdomain },
    body: {
      partnershipsEnabled,
      partnershipsRequiredMemberCount,
    },
  });
};

export const updatePartnershipStatus = (
  partnershipId: string,
  subdomain: string,
  status: PartnershipStatus,
) => {
  return apiV2.partnership.updatePartnershipRequest({
    params: { subdomain, partnershipId },
    body: {
      status,
    },
  });
};

export const createPartnership = async (
  subdomain: string,
  message: string,
  targetedCommunityId: string,
  contact: {
    telegramHandle?: string | undefined;
  },
) => {
  const response = await apiV2.partnership.createPartnershipRequest({
    params: { subdomain },
    body: {
      message,
      targetedCommunityId,
      contact,
    },
  });

  if (response.status === 400) {
    throw new Error(response.body.message);
  }
  if (response.status !== 201) {
    throw new Error('Cannot create partnership.');
  }
  return response.body;
};

export const searchPartnershipCommunities = async (
  subdomain: string,
  search: string,
  page: number = 0,
  pageSize: number = 40,
) => {
  const response = await apiV2.partnership.searchPartnershipCommunity({
    params: { subdomain },
    query: { search, page: String(page), pageSize: String(pageSize) },
  });

  if (response.status !== 200) {
    throw new Error('Cannot get new communities.');
  }

  return {
    results: response.body,
    nextPage: page + 1,
    hasNextPage: response.body.length === pageSize,
  };
};
