import { useQuery } from '@tanstack/react-query';

import type { PartnershipsRequestQuery, PartnershipStatus } from '@zealy/schemas';
import { getSubdomain } from '@zealy/utils';

import type { RQOptions } from '../../types';
import { partnershipKeys } from '../partnership.keys';
import { listPartnerships } from '../partnership.service';

type UseListPartnershipsArgs = {
  subdomain: string;
  query: PartnershipsRequestQuery;
};

export const useListPartnerships = ({
  subdomain = getSubdomain(),
  query,
  ...options
}: UseListPartnershipsArgs & RQOptions<typeof listPartnerships>) => {
  return useQuery({
    ...options,
    queryKey: partnershipKeys.listPartnerships(
      subdomain,
      query.status,
      query.status === 'pending' ? query.position : undefined,
    ),
    queryFn: () => listPartnerships(subdomain, query),
  });
};
